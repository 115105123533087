.page {
    background-color: $Back-ground;
    overflow-y: scroll;
    padding-bottom: 1.5em;

    .title {
        font-size: 1.6em;
        .back-btn {
            top: 0;
            left: 2.5%;
            line-height: 1;
            display: flex;
            align-items: center;
            color: $theme-blue;
            img {
                height: 0.3em;
                margin-right: 5px;
            }
        }
    }
    .plans-path {
        left: 10%;
        top: 0%;
        font-size: 12px;
        cursor: pointer;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
    .switch-button {
        background: $theme-light-blue;
        overflow: hidden;
        width: 12em;
        text-align: center;
        color: $theme-blue;
        position: relative;
        padding-right: 6em;
        position: relative;

        &:before {
            content: 'Yearly';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 6em;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            pointer-events: none;
        }

        &-checkbox {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;

            &:checked + .switch-button-label:before {
                transform: translateX(6em);
                transition: transform 300ms linear;
            }

            & + .switch-button-label {
                position: relative;
                padding: 0.5em 0;
                display: block;
                user-select: none;
                pointer-events: none;

                &:before {
                    content: '';
                    background: $White-Color;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: translateX(0);
                    transition: transform 300ms;
                    border: 1.4px solid $theme-light-gray;
                    border-radius: 1.5em;
                }

                .switch-button-label-span {
                    position: relative;
                }
            }
        }
    }

    .plan-card {
        background-color: $White-Color;
        border: 1.5px solid $theme-light-blue;
        width: 23%;
        position: relative;
        margin: 1%;
        height: auto;
        overflow: hidden;
        .card-name {
            font-size: 18px;
            margin-top: 26px;
        }
        .recommended {
            background-color: #133f7d;
            color: white;
            padding: 4px;
            font-size: 12px;
        }
        .month-price {
            color: $theme-blue;
            font-size: 22px;
        }
        .year-price {
            font-size: 18px;
        }
        .card-line {
            height: 1px;
            background-color: $theme-light-blue;
        }
        .detail-container-ul li {
            list-style: none;
            font-size: 14px;
        }
        .detail-container {
            // width: 75%;
            padding: 0 25px;
            color: $theme-subuser;
            // .f-reg {
            //   padding: 0;
            //   margin: 0;
            //   font-size: 15px;
            //   line-height: 3.5;
            // }
            .tick-mark {
                margin-top: 4px;
                margin-right: 10px;
            }
            .text-transform {
                text-transform: capitalize;
                line-height: 23px;
            }
        }
        .pb-10 {
            padding-bottom: 40px;
        }
        .add-on {
            // width: 70%;
            padding: 0 25px;
            cursor: pointer;
        }

        .add-on-option {
            // width: 70%;
            font-size: 1rem;
            padding: 0 10px;

            #add-on-option {
                -webkit-appearance: none;
                width: 0.7rem;
                height: 0.7rem;
                border: 1px solid $theme-blue;
                border-radius: 0.2em;
                position: relative;
                outline: none;
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==');
                    background-size: 2em;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        .sticker {
            position: absolute;
            top: -18px;
            left: 11px;
            width: 15%;
            transform: rotate(-90deg);
        }
        .plancard-btn {
            padding: 0.8em 1.6em;
            margin: 10px 0 15px 0;
        }

        .plan-card-button {
            background-color: $White-Color;
            color: $theme-blue;
            border: 1.2px solid $theme-blue;
        }

        .plan-card-button-active {
            background-color: $theme-blue;
            color: $White-Color;
            border: 1.2px solid $White-Color;
        }
        .plan-card-button-inactive {
            background-color: $theme-light-gray;
            color: $White-Color;
            border: 1.2px solid $White-Color;
        }
    }
    .active {
        border: 1.2px solid $theme-blue !important;
    }
}

.plancard-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.mr-2 {
    margin-right: 2px;
}
.mr-3 {
    margin-right: 10px;
}

.plan-upgrade {
    text-align: center;
    .alink {
        color: blue;
        cursor: pointer;
    }
}

.subscription-expired-page {
    h1 {
        color: $theme-peacock;
    }
    .sub-exp-img {
        width: 20%;
        height: auto;
        margin-bottom: 25px;
    }
    .plans-btn {
        width: 165px;
        height: 44px;
    }
}
