.login-main {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.login-left {
    display: flex;
    flex-basis: 30%;
    width: 100%;
    min-width: 30%;
    transition: 0.3s all linear;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.logo-img {
    margin-bottom: 0px;
    height: 6em;
    @media screen and (min-width: 1620px) {
        height: 8em;
    }
}

.login-right {
    display: flex;
    padding: 2em 3em;
    flex-direction: column;
    // gap: 1em;
    align-items: center;
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: $Back-ground;

    @media screen and (max-width: 769px) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: 1440px) {
        padding-left: 2em;
        padding-right: 2em;
    }

    @media screen and (min-width: 1536px) {
        padding-left: 4em;
        padding-right: 4em;
    }

    @media screen and (min-width: 1600px) {
        padding-left: 5em;
        padding-right: 5em;
    }

    @media screen and (min-width: 1700px) {
        padding-left: 7em;
        padding-right: 7em;
    }
    .highlight {
        text-decoration: none;
        color: $theme-blue;
        font-weight: 700;
    }
}

.loginFormContainer {
    padding: 0 3em;
    width: 100%;

    @media screen and (max-width: 922px) {
        padding: 0 1em;
    }

    @media screen and (max-width: 769px) {
        padding: 0 1em;
    }
}

.loginInputContainer {
    @media screen and (max-width: 580px) {
        width: 100%;
    }
    @media screen and (min-width: 600px) {
        width: 80%;
    }
    @media screen and (min-width: 700px) {
        width: 60%;
    }
    @media screen and (min-width: 1000px) {
        width: 50%;
    }
}

.login-signup-btn {
    @media screen and (max-width: 580px) {
        width: 80%;
    }
    @media screen and (min-width: 600px) {
        width: 60%;
    }
    @media screen and (min-width: 700px) {
        width: 80%;
    }
    @media screen and (min-width: 1000px) {
        width: 70%;
    }
}

.loginInputForm {
    display: flex;
    justify-content: space-between;
}

.forgot-password {
    cursor: pointer;
}

.tc-checkbox {
    height: 1.2em;
    width: 1.2em;
}
