.c-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $theme-blue;
    border: none;
    color: $White-Color;
    font-family: 'Manrope-Regular';
    padding: 1em;
}

.c-outline-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1.5px solid $theme-blue;
    color: $theme-blue;
    img {
        height: 1em;
        margin-left: 10px;
    }
}
.c-btn-small {
    max-height: 40px !important;
    // max-width: 80%;
}
.top-77 {
    top: 77px;
    position: relative;
}
.margin-bottom-4 {
    margin-bottom: 4px;
}

.info-feedback,
.feedback {
    border-style: none;
    background-color: $theme-blue;
    color: $White-Color;
    padding: 1em 0;
    border-radius: 0.5em;
    margin-right: 10px;
    cursor: pointer;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    img {
        height: auto;
        width: 10px;
        margin-left: 5px;
    }
    &:hover {
        color: white;
    }
}

.common-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $theme-blue;
    border: 1.5px solid $theme-blue;
    color: $White-Color;
    border-radius: 0.5em;
}

.disable-common-btn {
    background-color: $theme-gray;
}

.signin-signup-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: 'Manrope-Regular';
    padding: 1em;
    color: $White-Color;
}

.signin-blue {
    background-color: $theme-blue;
}

.signin-grey {
    background-color: $theme-subtitle-color;
}

.outline-btn {
    border: 1.5px solid $theme-blue;
    color: $theme-blue;
    padding: 0.6em 2em;
    border-radius: 0.5em;
}

.view-btn {
    border-style: none;
    background-color: $theme-light-blue;
    padding: 0.9em 0.7em;
    border-radius: 0.5em;
    right: 1%;

    img {
        width: 15px;
    }
}

.change {
    width: 100px;
    background-color: $White-Color;
}

//quick-create-btn//
.quick-create-container {
    position: relative;
    .quick-create-btn {
        border: none;
        cursor: pointer;
        background-color: $theme-blue;
        color: $White-Color;
        height: 30px;
        padding: 18px;
        .quick-arrow {
            height: 10px;
            width: 10px;
            margin-left: 10px;
        }
    }
    .quick-dropdown {
        position: absolute;
        z-index: 1000;
        left: 0;
        top: 120%;
        background-color: $White-Color;
        padding: 5px 10px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        .quick-drop-divider {
            height: 0.5px;
            background-color: #f4f4f4;
            margin: 5px 0;
        }
        .quick-drop-option {
            padding: 5px 10px;
            width: 155px;
            border-radius: 5px;
            cursor: pointer;
        }
        .quick-drop-option-enabled {
            cursor: pointer;
            color: $theme-blue;
            &:hover {
                background-color: $theme-light-blue;
            }
        }
        .quick-drop-option-disabled {
            cursor: not-allowed;
            background-color: #e9e9e9;
            color: #6f6f84;
        }
    }
}
//quick-create-btn//
