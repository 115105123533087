@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap');
.f-p-mid {
    font-weight: 500;
}
.f-p-bol {
    font-weight: 600;
}
.f-p-reg {
    font-weight: 300;
}
.video-main-container {
    background-color: #191924;
    height: 100%;
    * {
        padding: 0;
        margin: 0;
        color: white;
        font-family: 'Poppins', sans-serif;
    }
}

.left {
    border-right: 1px solid white;
}
// .right {
//   border-left: 1px solid white;
// }

.left-part1,
.right-part1 {
    border-radius: 0.5em;
    overflow: hidden;
    background-color: #2e2e41;
    height: 4em;
}
.left-part1 {
    .col-lg-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            img {
                height: 1em;
                margin-right: 5px;
            }
        }
    }
}

.left-part2 {
    height: 84%;
}

.right {
    .right-part1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;

        button {
            border-style: none;
            background-color: #ec2d38;
            border-radius: 0.5em;
            padding: 0.6em;
            color: white;

            img {
                width: 1.2em;
                margin-right: 8px;
            }
        }
    }
    .right-part2 {
        height: auto;
        display: flex;
        justify-content: center;

        .btn-container {
            width: 15%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .inactive {
                background-color: #20202d;
            }

            .active {
                background-color: #13b1b6;
            }

            button {
                border-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: auto;
                margin-bottom: 8px;
                padding: 10px 0;
                border-radius: 0.3em;

                img {
                    height: 1em;
                }
            }
        }
        .skill-board {
            width: 85%;
            background-color: #2e2e41;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;

            .ms-box {
                input,
                select,
                textarea {
                    outline: none;
                    border-style: none;
                    border: 1.4px solid #505160;
                    background-color: transparent;
                    border-radius: 0.6em;
                }
                input {
                    width: 57.5%;
                    height: 3.5em;
                    padding: 10px;
                }
                select {
                    width: 22.5%;
                    height: 3.5em;
                    option {
                        background: #2e2e41;
                    }
                }
                .delete-btn {
                    border-style: none;
                    border: 1.4px solid red;
                    border-radius: 0.6em;
                    height: 3.5em;
                    width: 14%;
                    background-color: transparent;
                    img {
                        width: 1.6em;
                    }
                }
                textarea {
                    width: 100%;
                    height: 5em;
                    padding: 10px;
                    &::placeholder {
                        color: #505160;
                    }
                }

                .qs-box {
                    input {
                        border: none;
                    }
                }
            }

            .add-more {
                cursor: pointer;
            }

            .fr-box {
                .remark-box {
                    textarea {
                        outline: none;
                        border-style: none;
                        border: 1.4px solid #505160;
                        background-color: transparent;
                        border-radius: 0.6em;
                        width: 100%;
                        height: 7em;
                        padding: 10px;
                        &::placeholder {
                            color: #505160;
                        }
                    }
                    span {
                        color: red;
                    }
                }
                .code-box {
                    textarea {
                        outline: none;
                        border-style: none;
                        border: 1.4px solid #505160;
                        background-color: transparent;
                        border-radius: 0.6em;
                        width: 100%;
                        height: 5em;
                        padding: 10px;
                        &::placeholder {
                            color: #505160;
                        }
                    }
                }
                .rating-box {
                    select {
                        width: 22.5%;
                        height: 3.2em;
                        outline: none;
                        border-style: none;
                        border: 1.4px solid #505160;
                        background-color: transparent;
                        border-radius: 0.6em;
                        option {
                            background: #2e2e41;
                        }
                    }
                }

                .radio-container {
                    height: 6em;

                    .checkbox-container {
                        display: flex;
                        align-items: center;
                        input[type='checkbox'] {
                            accent-color: #5c5ca5;
                            margin-right: 5px;
                            width: 1.5em;
                            height: 1.5em;
                        }
                    }
                }

                .submit {
                    border-style: none;
                    background-color: #13b1b6;
                    display: block;
                    padding: 0.4em 2em;
                    border-radius: 0.5em;
                }
            }
        }
    }
}
