.landingContainer {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
}

.landing-middle {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: $theme-mercury;
    overflow-y: auto;
    padding: 30px;
    &::-webkit-scrollbar-thumb {
        background: $theme-peacock;
        border-radius: 0;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
    }

    @media screen and (max-width: 402px) {
        padding: 1em;
    }
    .subscription-box {
        .subscription-card {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            background-color: $White-Color;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
            margin-bottom: 20px;
            padding: 10px 20px;
            @media screen and (max-width: 598px) {
                flex-direction: column;
            }
            .subscription-detail {
                .subscription-card-icon {
                    width: auto;
                    height: 40px;
                }
            }
            .dashboard-btn-box {
                @media screen and (max-width: 598px) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.landing-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    background-color: $White-Color;
}

.ltb-1-subtitle {
    color: $theme-placeholder;
}

.ltb-notification {
    height: 1em;
    cursor: pointer;
}

.lp-products {
    color: $theme-blue;
    margin-top: 25px;
    font-size: 30px;
    @media screen and (max-width: 992px) {
        font-size: 25px;
    }
}

.lp-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @media screen and (max-width: 1008px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.lp-card-3 {
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.lp-copy {
    position: absolute;
    top: 1em;
    right: 1em;
}

.lp-main-card {
    position: relative;
    .Subscribed {
        background-color: $theme-blue;
        color: $White-Color;
        width: 122px;
        height: 22px;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
    }
    .Expired,
    .Cancelled {
        background-color: $theme-error;
        color: $White-Color;
        width: 122px;
        height: 22px;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
    }
    .Unsubscribed {
        width: 122px;
        height: 22px;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
    }
    .lp-card {
        border-radius: 5px;
        background-color: $White-Color;
        padding: 1em;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-width: 340px;
    }

    .lp-card-img {
        height: 2.5em;
    }

    .lp-card-name {
        color: $theme-blue;
        margin-left: 20px;
        font-size: 16px;
        line-height: 20px;
    }

    .lp-card-title {
        display: flex;
        align-items: center;
    }

    .lpc-green-tick {
        height: 0.8em;
        margin-right: 5px;
        margin: 0.5em;
    }

    .lpc-detail {
        color: $theme-placeholder;
        width: 48%;
        display: flex;
        .lpc-points {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: default;
        }
    }

    .lpc-price {
        color: $theme-blue;
        font-size: 20px;
        line-height: 20px;
    }
}
.lpc-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    height: 3em;
    padding: 0 15px;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $White-Color;
    }
}
.lpc-card-btn {
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid $theme-blue;
    color: $theme-blue;
    width: 125px;
    height: 100%;
    &:hover {
        color: $theme-blue;
    }
}

.lpc-round {
    border-radius: 4px;
}

.lpc-btn-v2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    height: 3em;
    padding: 0 15px;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $White-Color;
    }
}

.lpc-blue {
    background-color: $theme-blue;
    color: $White-Color;
}

.lpc-white {
    background-color: #43b6bc;
    color: $White-Color;
    border: 1px solid #43b6bc;
}

.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;

    @media screen and (max-width: 520px) {
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;
    }
}

.landing-right {
    display: flex;
    height: 100%;
    width: 25%;
    flex-direction: column;
    background-color: $theme-blue;
    padding: 1em;
    align-items: center;
    overflow-y: auto;
    align-items: center;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.nameChip {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    width: fit-content;
    border-radius: 50px;
    height: 36px;
    background-color: #ffffff;
    padding: 10px;
    gap: 0.4em;
    text-align: center;

    .nameCircle {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $theme-blue;
        color: #ffffff;
    }
}

.profileDisplay {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .profileCircle {
        border: 2px solid $White-Color;
    }
}

.simpleDivider {
    min-height: 1px;
    width: 100%;
    background-color: #ffffff;
}

.simpleDividerGray {
    min-height: 2px;
    width: 100%;
    border: 2px solid #f6f7fb;
    margin: 0.5em 0;
}

.viewProfieBtn {
    padding: 0.6em 2em;
    background-color: #ffffff;
    display: flex;
    font-weight: bold;
    color: $theme-blue;
    text-align: center;
    border: none;
    border-radius: 0.5em;
}

.mySubscriptionsCard {
    display: flex;
    border-radius: 10px;
    width: 100%;
    padding: 1em;
    background-color: #ffffff;
    flex-direction: column;
    gap: 0.3em;
}

.productCardContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.landingCard {
    padding: 1em;
    border-radius: 0.5em;
    flex-grow: 0;
    flex-shrink: 1;
    flex: 1;

    @media screen and (max-width: 922px) {
        max-width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.userInfoBar {
    width: 100%;
    padding: 1em 2em;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 12px;
    justify-content: space-between;
    transition: all 0.1s linear;

    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 0.5em;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .userInfoBarRight {
        @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 0.5em;
        }
    }
}

.userInfo {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    border-radius: 10px;
}

.landingBarChart {
    // max-height: 200px;
    // max-width: 563px;
    flex-grow: 1;
}

.landingCharts {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    // width: 200px;
}

.iconWrapper {
    height: 50px;
    width: 50px;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f7fb;
    border-radius: 5px;
}

.landing-card-img {
    height: 1em;
}
.react-calendar__navigation__arrow {
    min-width: 1em !important;
}

.pu-popup-box {
    width: 38%;
    left: 25%;
    top: 25%;
    background-color: $White-Color;
    padding: 2em;
    text-align: center;
    .pu-subtitle {
        margin: 15px 0 25px 0;
    }
    .pu-btn {
        padding: 0.6em 2em;
    }
    .ok-btn {
        padding: 15px 40px;
        color: $White-Color;
        border-style: none;
        background-color: $theme-light-green;
    }
    @media screen and (max-width: 992px) {
        width: 90%;
    }
}

.lp-profile-pic {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .user-profile-img {
        // height: 100%;
        height: 60%;
        object-fit: cover;
        width: auto;
    }
}

.lp-subcard {
    border-top: 1px solid $theme-placeholder;
    padding-top: 10px;
    img {
        height: 1.5em;
    }
}

.lp-subcard-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lp-progress {
    height: 2px;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: $theme-light-gray;
    width: 50%;
}

.lp-progress-bar {
    height: 100%;
    margin: 0;
    background-color: $theme-peacock;
}

.interviewer-stats-container {
    background-color: white;
}

.theme-grey {
    color: #626262;
    line-height: 10px;
}
