// Color
$theme-black: #0c0c0d;
$White-Color: #ffffff;
$theme-placeholder: #8780ab;
$theme-red: #ff0000;
$theme-peacock: #12b6bc;
$theme-blue: #133f7d;

.apexcharts-legend-marker {
    background-color: red !important;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('../fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('../fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

body,
html {
    width: 100%;
    height: 100%;
    background-color: $White-Color;
    scroll-behavior: smooth;
    font-family: 'Manrope-Regular';
}

#root {
    // display: flex;
    height: 100%;
    width: 100%;
}
.top-0 {
    top: 38% !important;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $theme-blue;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $theme-blue;
}

//code to hide the arrows in the number input element
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
//code to hide the arrows in the number input element

a {
    text-decoration: none;
    color: white;

    :hover {
        text-decoration: none;
    }
}

.f-mh {
    font-size: 58px;
}

.f-h {
    font-size: 38px;
}

.f-fh {
    font-size: 20px;
}

.f-sh {
    font-size: 16px;
}

.f-l {
    font-size: 12px;
}

.f-ph {
    font-size: 10px;
}

.f-c {
    font-size: 14px;
}

.f-s {
    font-size: 8px;
}

.f-xs {
    font-size: 6px;
}

.f-6 {
    font-size: 6px;
}

.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 10px;
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-15 {
    font-size: 15px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
    line-height: 21px;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

.f-18 {
    font-size: 18px;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;

    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}

.f-24 {
    font-size: 24px;

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}

.f-26 {
    font-size: 26px;

    @media screen and (max-width: 767px) {
        font-size: 22px;
    }
}

.f-36 {
    font-size: 36px;

    @media screen and (max-width: 767px) {
        font-size: 34px;
    }
}

.f-28 {
    font-size: 28px;

    @media screen and (max-width: 767px) {
        font-size: 24px;
    }
}

.f-40 {
    font-size: 40px;
}

.f-51 {
    font-size: 51px;
    line-height: 69px;

    @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.f-60 {
    font-size: 60px;
}

.f-reg {
    font-family: 'Manrope-Regular';
}

.f-sem-bol {
    font-family: 'Manrope-SemiBold';
}

.f-med {
    font-family: 'Manrope-Medium';
}

.f-lig {
    font-family: 'Manrope-Light';
}

.f-ex-lig {
    font-family: 'Manrope-ExtraLight';
}

.f-ex-bol {
    font-family: 'Manrope-ExtraBold';
}

.f-bol {
    font-family: 'Manrope-Bold';
}

.w-50 {
    width: 50%;
}

.w-48 {
    width: 48%;
}

.w-40 {
    width: 40%;
}

.w-33 {
    width: 33%;
}
.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-5 {
    width: 5%;
}
.w-7-5 {
    width: 7.5%;
}
.w-10 {
    width: 10%;
}
.w-12 {
    width: 12%;
}
.w-12-5 {
    width: 12.5%;
}
.w-13 {
    width: 13%;
}
.w-25 {
    width: 25%;
}
.w-90 {
    width: 90%;
}
.w-80 {
    width: 80%;
}
.w-65 {
    width: 65%;
}
.w-60 {
    width: 62%;
}
.padd-0-5 {
    padding: 0.5%;
}
.opacity-100 {
    opacity: 100;
}

.f-right {
    float: right;
}

.cursor-pointer {
    cursor: pointer !important;
}

.no-wrap {
    white-space: nowrap;
}

.text-theme-black {
    color: $theme-black;
}

.text-theme-red {
    color: $theme-error;
}

.text-theme-blue {
    color: $theme-blue;
}
.align-self-end {
    align-self: flex-end;
}

.text-theme-gray {
    color: $theme-gray;
}

.text-theme-white {
    color: $White-Color;
}

.cursor-na {
    cursor: not-allowed !important;
}

.social-sign-in-btn {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0 30px;
    border: 1px solid $theme-border;
    font-family: 'Manrope-Bold';
    font-size: 14px;
    line-height: 21px;
    color: $theme-black;
    background-color: $White-Color;

    @media screen and (max-width: 769px) {
        font-size: 12px;
        width: 100%;
    }
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.divider-container {
    padding: 1em;

    .divider {
        display: inline-block;
        height: 1px;
        width: 80%;
        background-color: $theme-border;
    }
}

.pl-30 {
    padding-left: 30px;
}

.content-main {
    // transition: 0.2s all linear;
    background-color: $theme-mercury;
    min-height: 100%;
    height: 100%;

    @media screen and (max-width: 992px) {
        padding-left: 0px;
    }
}

button {
    box-shadow: none !important;
}

// * {
//   transition: 0.2s all linear;
// }

.lrf-banner-img {
    background-image: url('../images/lrf-banner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $theme-blue;
}

.myProfileDivider {
    width: 100%;
    height: 1px;
    background-color: $theme-blue;
    margin-bottom: 1em;

    @media screen and (min-width: 993px) {
        display: none;
    }
}

.incVid-logo {
    color: #626262;
    cursor: default;
}

.goBackBadge {
    color: $theme-gray;
    cursor: pointer;

    &:hover {
        color: $theme-blue;
    }

    @media screen and (min-width: 993px) {
        display: none;
    }
}

.smallBtn {
    max-width: 40%;
    min-width: 40%;
}

.icon {
    margin: 0 0.4em 0 0;
}

// Please dont remove this
.main-container {
    // flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.header-container {
    height: 60px;
    z-index: 10000;
}

.content-container {
    display: flex;
    height: calc(100% - 60px);
    width: 100%;
}

.sidebar-container {
    flex: 0.25;
    height: 100%;
    width: 100%;
    transition: all 0.1s ease-in;
    // @media screen and (max-width: 992px) {
    //   display: none;
    // }
}

.sidebar-container-collapsed {
    height: 100%;
    transition: all 0.1s ease-in;
    // @media screen and (max-width: 992px) {
    //   display: none;
    // }
}

.page-container {
    display: flex;
    flex: 1;
    height: 100%;
}

.page-expand {
    width: calc(100% - 80px);
}

.page-shrink {
    width: 80%;
}

.fontThemeColor {
    color: $theme-blue !important;
}

.fontGray {
    color: #83879e !important;
}

.inlineTitle {
    margin-right: 0.5em;
}

.globalSubtitle {
    color: $theme-subtitle-color;
}

.my-calender {
    color: $theme-blue;
}

.gray {
    color: $theme-subuser;
}

.sectionTitle {
    color: $theme-blue;
    font-size: 22px;
}

.flexDontGrow {
    flex-grow: 0 !important;
}

.padding-1 {
    padding: 1em !important;
}

// shadows
.shadow-1 {
    box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.shadow-3 {
    box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
    box-shadow:
        0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
    box-shadow:
        0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
}
.shadow-6 {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px;
}

//confirm delete popup
.delete-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .support-link {
        color: $theme-blue;
        cursor: pointer;
    }
    .info-logo {
        height: 8px;
        width: auto;
    }

    .db-cancel-interview-btn {
        background-color: #d92d20;
    }

    .confirm-delete-box {
        top: 5%;
        position: absolute;
        background-color: $White-Color;
        width: 500px;

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .delete-box-logo {
            width: 5em;
            margin-bottom: 30px;
        }
        .width-logo {
            width: 5rem !important;
            margin-bottom: 12px;
        }
        .font-title {
            font-size: 20px !important;
            margin-bottom: 5px;
        }
        .font-subtitle {
            font-size: 14px;
            width: 70%;
        }

        .email-verification-sub {
            font-size: 18px;
        }
        .img-align {
            align-self: flex-end;
            width: 2rem;
        }
        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 6em;
            color: $White-Color;
        }

        .db-delete-btn {
            background-color: #d92d20;
        }

        .db-cancel-btn {
            background-color: #626262;
        }

        .db-submit-btn {
            color: $theme-blue;
            background-color: $theme-light-blue;
        }
    }

    .time-slot-container {
        width: 530px;
        height: 500px;
        background-color: $Back-ground;
        border-radius: 10px;
        .timeslot-close {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 20px;
            right: 10px;
            border-radius: 50%;
            background-color: $theme-blue;
            border: none;
            .white-close {
                height: 10px;
                width: auto;
            }
        }
        .slot-card-container {
            height: 90%;
            overflow-y: auto;
            .slot-card {
                background-color: $theme-light-blue;
                border-radius: 10px;

                .slots-container {
                    display: flex;
                    flex-wrap: wrap;
                    .time-container {
                        border: 1.5px solid #becada;
                        padding: 5px;
                        color: $theme-blue;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

//email-verification-popup

.email-verification-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9998;
    overflow-y: auto;

    .center-box {
        position: absolute;
        left: 30%;
        width: 70%;
        height: 100%;

        @media screen and (max-width: 992px) {
            width: 100%;
            left: 0;
        }
    }

    .email-verification-box {
        width: auto;
        background-color: $White-Color;

        .vari-close {
            position: absolute;
            right: 4px;
            top: 4px;
        }

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .delete-box-logo {
            width: 5em;
            margin-bottom: 30px;
        }

        .email-verification-sub {
            font-size: 16px;
        }

        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 8em;
        }

        .db-delete-btn {
            color: $theme-red;
            background-color: $theme-light-red;
        }

        .db-cancel-btn {
            color: $theme-placeholder;
            background-color: $theme-light-blue;
        }
    }
}

.input-shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.input-error {
    border: 2px solid #ed184f !important;
}

.expiry-alert {
    background-color: $theme-carrot;
    color: $White-Color;
    word-spacing: 2.5px;
    text-align: center;

    .link {
        text-decoration: underline;
        text-decoration-color: $White-Color;
        cursor: pointer;
    }

    .hide {
        cursor: pointer;
        border: 1px solid $White-Color;
        border-radius: 50%;
        right: 1%;
        top: 18%;
        height: 16px;
        width: 16px;
        padding: 2px;

        img {
            height: 12px;
            width: auto;
        }
    }
}

.text-animate {
    animation-name: credit;
    animation-duration: 4s;
}

@keyframes credit {
    0% {
        left: 0px;
        top: 0px;
    }

    100% {
        left: 100%;
        top: 0px;
    }
}

.credit-box-container {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    z-index: 1000;

    .credit-topup-box {
        position: absolute;
        background-color: $White-Color;
        top: 10%;
        right: 25%;
        padding: 20px;

        .title {
            margin-bottom: 6px;

            .btn-close {
                height: 0.4em;
                width: 0.4em;
            }
        }

        .select-credit {
            margin-right: 5px;

            .credits {
                margin: 6px 0;

                span {
                    border: 1px solid $theme-blue;
                    color: $theme-blue;
                    height: 2em;
                    width: 2.5em;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.2em;
                    cursor: pointer;

                    &:hover {
                        background-color: $theme-blue;
                        color: $White-Color;
                    }
                }
            }
        }

        .credit-price {
            margin-left: 5px;
        }

        input {
            outline: 0;
            border-style: none;
            height: 3em;
            width: 10em;
            margin: 8px 0;
            border-radius: 0.25em;
            padding: 5px;
        }

        .buy-now {
            padding: 0.6em 2em;
            border-radius: 0.5em;
            margin: 6px auto 0 auto;
        }
    }
}

.Toastify__toast {
    width: 100%;
    font-size: 15px;
}

.Toastify__toast-icon {
    margin-right: 20px;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.d-end {
    display: flex;
    align-items: end;
    justify-content: end;
}

// website css //
.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 10px;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-20 {
    font-size: 20px;
}

.f-26 {
    font-size: 26px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.f-34 {
    font-size: 34px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.f-46 {
    font-size: 46px;

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
}

.f-76 {
    font-size: 76px;

    @media screen and (max-width: 600px) {
        font-size: 40px;
    }
}

//blue-btn
.blue-btn {
    border-style: none;
    background-color: $theme-blue;
    border-radius: 0.5em;
    color: $White-Color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;

    img {
        margin-right: 10px;
        height: 0.8em;
    }
}

//new pages//
.app-container {
    background-color: $Back-ground;
}

//product-intro//
.product-intro {
    height: 100vh;
    position: relative;
    overflow: hidden;

    .content {
        position: absolute;
        width: 40%;
        top: 40%;
        left: 5%;

        .subtitle {
            text-align: justify;
        }

        @media screen and (max-width: 992px) {
            width: 80%;
            top: 58%;
            left: 10%;
        }

        .product-name {
            color: $theme-peacock;
        }
    }

    .slider-img {
        position: absolute;
        width: 40%;
        top: 30%;
        right: 5%;

        @media screen and (max-width: 992px) {
            top: 25%;
            width: 60%;
            right: 20%;
        }
    }

    .blue-bg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}

.pointer {
    cursor: pointer;
}

.cstm-select-box .select__menu {
    position: absolute !important;
    top: initial !important;
    left: 0;
    bottom: 100% !important;
    z-index: 9 !important;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

.gradeOut {
    opacity: 0.4;
}

// website css //
.w-47 {
    width: 47%;
}

//BREAD CRUMBS//
.breadcrumb-item {
    cursor: pointer;
}

.success-btn {
    padding: 0.6em 2em;
    margin: 0 10px;
}

.pay-success-4 a:focus,
.pay-success-4 a:hover {
    color: #fff;
}

.text-theme-white {
    color: $White-Color;
}

.text-theme-placeholder {
    color: $theme-placeholder;
}

.Toastify__toast-container {
    z-index: 10000000 !important;
}

.ticket-text-area {
    resize: none !important;
    height: 100px;
    border-radius: 5px !important;
}
.text-area {
    resize: vertical !important;
}

.hidden {
    display: none;
}

.css-26l3qy-menu > div {
    max-height: 150px;
}

.float-right {
    float: right;
}

.circular-graph-box-p2 .CircularProgressbar {
    padding: 20px;
}

.download-sample {
    color: var(--bs-link-hover-color);
    margin-left: 10px;
}

.DateRangePicker__Date {
    width: 40px !important;
    height: 40px !important;
    padding: 2px !important;
}

.DateRangePicker__CalendarSelection--start,
.DateRangePicker__CalendarSelection--end,
.DateRangePicker__Date--is-selected,
.DateRangePicker__Date--is-highlighted {
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    border-radius: 50% !important;
    background-color: #123a75 !important;
    font-weight: 500 !important;
    color: #fff !important;
}

.DateRangePicker__CalendarSelection,
.DateRangePicker__CalendarHighlight {
    border: none !important;
    background-color: #123a75 !important;
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
}

.DateRangePicker {
    display: block !important;
    text-align: center !important;
}

.calendar-slots-container .calendar {
    padding: 10px;
}

.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue,
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #123a75 !important;
}

.rmdp-week-day {
    color: #000 !important;
}

.panelist-title-center {
    align-items: center;
    justify-content: center;
}

.rmdp-panel-body span {
    font-size: 11px !important;
    font-weight: 100;
    line-height: 20px !important;
    font-family: 'Manrope-Regular';
}

.rmdp-panel {
    min-width: 100px !important;
}

.rmdp-shadow {
    box-shadow: 0 0 1px #8798ad !important;
    border: none !important;
    display: block;
    margin: 0 auto;
}

.slot-title {
    font-size: 12px;
    text-align: left !important;
    padding: 2px;
}

.slot-btn {
    line-height: 1.5;
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    text-transform: none;
    will-change: transform;
    font-size: 0.6rem;
}

.time-slot-div {
    border-radius: 3px;
    box-shadow: 0 0 2px #8798ad;
    color: #fff;
    display: flex;
    margin: 5px;
    background-color: #123a75;
    position: relative;
    width: 100px;
    font-size: 12px;
}

.date-slot-div {
    background-color: #fff;
    color: #123a75;
    border-left: 2px solid #123a75;
    text-align: center;
    border-radius: 3px;
    position: relative;
    width: 120px;
    padding: 3px;
    box-shadow: 0 0 2px #8798ad;
    font-size: 12px;
    margin: 5px;
}

.time-slot-span {
    padding: 3px 5px;
}

.review-slot-deselect {
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    font-family: Arial !important;
    font-size: 16px;
    height: 12px;
    justify-content: center;
    line-height: 5px;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 52%;
    transform: translateY(-50%) rotate(45deg);
    width: 12px;
}

.slot-mbtn {
    margin-bottom: 12px;
    display: flex;
}

.overflow-yes {
    overflow: auto;
}

.top-toggle {
    top: 2px;
    margin: 0px 5px;
}

.date-time-slot-div {
    max-height: 360px;
    overflow: auto;
}

.max-wid-150 {
    max-width: 150px;
}

.c-label {
    font-family: 'Manrope-SemiBold';
    color: $theme-black !important;
}

.eye-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

.common-input {
    width: 100%;
    border: none;
    padding: 1.2em;
    font-family: 'Manrope-Regular';
    outline: none !important;
    background-color: $White-Color;
    &::placeholder {
        color: $theme-placeholder;
    }
}

.text-area {
    resize: none;
    padding: 15px;
}

.select__control {
    border-style: none !important;
}

.input-field-disabled {
    background-color: #e7e7e7 !important;
    color: #000000 !important;
}

.select__control--is-disabled {
    background-color: #e7e7e7 !important;
    .css-olqui2-singleValue {
        color: #000000 !important;
    }
}

.select__control--is-focused {
    border-style: none !important;
    border-color: white !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.asterisk {
    color: $theme-red;
}

.wrapper {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
    display: flex;

    .loader {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 5px solid $theme-blue;
        border-top-color: #ffffff;
        box-sizing: border-box;
        background: transparent;
        animation: loading 1s linear infinite;
        margin: auto;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        0% {
            transform: rotate(360deg);
        }
    }
}

.cld-unavailable {
    background: #d0d0d8;
    border: 1.5px solid #d0d0d8 !important;
}

.create-jd-btn {
    float: right;
    display: inline-block;
    margin-top: -47.8px;
    width: 33%;
    background: #11b2b8;
    color: #fff;
    height: 47.8px;
    border: none;
    border-radius: 0.5em;
}

.create-jd-btn-2 {
    float: right;
    display: inline-block;
    margin-top: -47.8px;
    width: 33%;
    background: #626262;
    color: #fff;
    height: 47.8px;
    border: none;
    border-radius: 0.5em;
    cursor: initial !important;
}

.jd-to-cv-match-btn {
    display: inline-block;
    width: 200px;
    background: #11b2b8;
    color: #fff;
    height: 40px;
    border: none;
    margin-top: 30px;
    width: 160px;
}
.save-field {
    background: #11b2b8;
    color: #fff;
    padding: 0px 20px;
    height: 46px;
    margin-top: 10px;
    border: none;
}

.jd-to-cv-match-btn-2 {
    display: inline-block;
    width: 200px;
    background: #626262;
    color: #fff;
    height: 40px;
    border: none;
    margin-top: 30px;
    width: 160px;
}

.create-jd-div {
    width: 65% !important;
}

.jDDataValue-container {
    background-color: #ffffff;
    // border: 10px solid #f0f2f9;
    padding: 10px;
    .jd-title-container {
        display: flex;
        justify-content: space-between;
        text-wrap: wrap;
        width: 100%;
        .jd-title {
            font-family: 'Manrope-SemiBold';
            font-weight: 600;
            color: '#171717';
        }
        .jd-company-name {
            font-family: 'Manrope-Regular';
            font-weight: 400;
            margin-bottom: 16px;
            color: '#191919';
        }
        .jd-title-divider {
            height: 20px;
            width: 1.2px;
            background-color: black;
            margin: 0 5px;
        }
    }
    .jd-logo {
        width: 149px;
        height: 61px;
        position: absolute;
        right: 10px;
        top: 10px;
        object-fit: contain;
    }
}

.jDDataValue-div {
    &::-webkit-scrollbar {
        display: none;
    }
    width: 100%;
    font-family: 'Manrope-Regular';
    margin: 0px !important;
    background: #fff;
    resize: none;
    overflow-y: auto;
    border: none;
    color: #171717;
    word-break: keep-all;
    &::-webkit-scrollbar {
        display: none;
    }
    &:focus {
        outline: none;
    }
}

.pc-progress-new-bar {
    margin: 10px 0px;
    height: 1.5em;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: #c5c5c5;
    .pc-progress-bar {
        background-image: linear-gradient(to right, #cadff5, #133f7d);
    }
}

.question-container .pc-total {
    font-size: 14px;
    margin-bottom: 10px;
    color: #204984;
}

.threedot-menu {
    background-color: $White-Color !important;
    border: none !important;
    .threedot-menu-content {
        height: 2.5em;
        width: 15em;
        cursor: pointer;
        color: #626262;
        &:hover {
            background-color: $theme-light-blue;
            color: $theme-blue;
            padding: 14px;
        }
        @media screen and (max-width: 992px) {
            width: 10em;
        }
    }
    .threedot-menu-content-disabled {
        background-color: #e7e7e7 !important;
    }
    .threedot-btn {
        border-style: none;
        background-color: $theme-light-blue;
        height: 2.4em;
        width: 2.4em;
        .threedot-img {
            height: 1em;
        }
    }
}
.subscription-choose {
    float: right;
    margin: 0px 0px 10px 0px;
}
.product-status-show {
    color: red;
    font-size: 12px;
}

// COMOON COMPONENT POPUP
//confirm delete popup
.delete-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .common-popup-box {
        // top: 5%;
        position: absolute;
        background-color: #ffffff;
        width: 400px;
        box-shadow:
            0px 20px 24px -4px rgba(16, 24, 40, 0.1),
            0px 8px 8px -4px rgba(16, 24, 40, 0.04);
        border-radius: 12px;

        .box-logo {
            width: 4em;
        }

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .email-verification-sub {
            font-size: 18px;
        }

        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 10em;
            color: $White-Color;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
        }

        // .db-delete-btn {
        //   background-color: #f77563;
        // }

        .db-cancel-btn {
            background: transparent;
            border: 1px solid #d0d5dd;
            color: #000;
        }
        .db-second-btn {
            background: #039855;
            border: 1px solid #18ac00;
        }
        .db-submit-btn {
            color: $theme-blue;
            background-color: $theme-light-blue;
        }
    }
}

.print-text-content {
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #171717;
    font-family: 'Manrope-Regular';
    word-break: break-word !important;
    white-space: pre-wrap !important;
}
.print-main-container {
    background: #ffffff;
    padding: 20px;
    height: 100%;
    // border: 10px solid #f0f2f9;
}

.print-div-container {
    background: #ffffff;
}
.jd-logo-1 {
    width: 80px;
    height: auto;
    position: absolute;
    right: 30px;
    top: 30px;
}
.hyperlink {
    text-decoration: underline;
}
.tabel-header {
    vertical-align: top !important;
}

.interview-logs-table {
    .interview-logs-header,
    .interview-logs-cell {
        padding: 5px 8px;
    }
}

.reason-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.reason-content {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.reason-container:hover .reason-content {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
    z-index: 9999;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.permission-container select:disabled {
    opacity: 1;
    background: #e6e9f8;
}
.ant-space-gap-col-small {
    column-gap: 0px !important;
}
.google-img {
    margin-right: 5px;
}
#dropdown-basic {
    background-color: #e6e9f8;
    border-style: none;
    height: 24px !important;
    width: 24px !important;
    padding: 0px !important;
}
#dropdown-basic .threedot-img {
    height: 10px !important;
    margin-top: -2px;
}
#dropdown-basic-transparent {
    background-color: transparent;
    border-style: none;
    height: 24px !important;
    width: 24px !important;
    padding: 0px !important;
}

#dropdown-basic-transparent .threedot-img {
    margin-top: -2px;
}
.dropdown-toggle::after {
    display: none !important;
}
.no-sub-box {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    padding: 10px 20px;
}
.form-control:disabled {
    background-color: transparent;
}
.panelist-Skills-container {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.panelist-Skills {
    background-color: #12b6bc;
    color: white;
    width: 60px;
    height: auto;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    margin: 0 1px;
    font-size: 10px;
    cursor: default;
}

.panelist-Skills:hover {
    user-select: all;
}
.resumeScore-img {
    width: 118px;
    height: 38px;
}
.tooltip-text {
    color: #133f7d;
    text-align: center;
    top: calc(50% - 9px);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: 12px;
}
.not-available {
    color: #a9a9ab;
    font-weight: 700;
    font-family: 'Manrope-Regular';
    text-decoration: none;
}
.dashboard-calendar-container {
    label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 5px;
        span {
            margin: 5% 0;
        }
        input {
            padding: 3px 5px;
            border-style: none;
            border-radius: 5px;
            color: $theme-subuser;
            outline: none;
            border: 1px solid $theme-border;
            &::-webkit-calendar-picker-indicator {
                background-image: url('../images/calender.svg');
            }
        }
    }
}
.instruction-code {
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    color: #83879e;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.go-to-dashboard {
    font-size: 13px;
    font-weight: 600;
    color: #626262;
}

.bot-toggle-save {
    .btn {
        height: 23px;
        width: 48px;
    }
}

.speaker-img {
    height: 22px;
    margin-right: 10px;
}

.white-label-div {
    .white-head {
        font-size: 12px;
        font-weight: 600;
    }
    .white-subhead {
        font-size: 8px;
        font-weight: 300;
    }
    .support-text {
        color: #8780ab;
        font-size: 8px;
        font-weight: 500;
    }
    .logo-label {
        height: 30px;
        width: 182px;
    }
    @media screen and (max-width: 1145px) {
        .logo-label {
            height: 30px;
            width: 160px;
        }
    }
    @media screen and (max-width: 1095px) {
        .logo-label {
            height: 30px;
            width: 140px;
        }
    }
    @media screen and (max-width: 1060px) {
        .logo-label {
            height: 30px;
            width: 120px;
        }
        .support-text {
            color: #8780ab;
            font-size: 6px;
            font-weight: 500;
        }
    }
    .input-file-name {
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .select-file {
        color: #626262;
    }
}

.cross-icon {
    position: absolute;
    top: -5.5px;
    right: -5px;
}

.no-label-image {
    height: 72px;
    width: 116px;
    object-fit: contain;
}

.bg-black {
    background-color: #000;
}

.dashed-border {
    border: 0.1px solid #7e7c7c;
}

.gap-2 {
    gap: 2px;
}

.white-disable {
    background-color: #e7e7e7 !important;
}

.disable-bg-color {
    border: none;
    background-color: #626262;
}

.disable-color {
    color: #626262;
}

.disable-dash-border {
    border: 0.1px dashed #a9a9ab !important;
}

.cursor-default {
    cursor: default;
}
.code-content-pre {
    white-space: pre-wrap; /* Allows the text to wrap */
    overflow: hidden; /* Hides any overflow (optional, based on your needs) */
    word-wrap: break-word; /* Breaks long words */
}

.code-content-pre code {
    font-family: 'Manrope-Regular';
    display: block; /* Make sure the code takes full width */
}

.connect-calendar-card {
    padding: 15.5px 13px;
    border: 0.3px solid #cccbcb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .head-text {
        color: #133f7d;
        // font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
    }

    .head-text.disabled {
        color: #626262;
    }

    .body-text {
        color: #797979;
        // font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .line {
        background-color: #cccbcb;
        height: 0.3px;
        width: 95%;
    }

    .connect-btn {
        padding: 0px 20px;
        border-radius: 5px;
        background-color: #133f7d;
        border: 1px solid #133f7d;
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 240% */
        letter-spacing: 0.5px;
    }

    .connect-btn.disabled {
        background-color: #626262;
    }

    .connected-btn {
        padding: 0px 12px;
        border-radius: 5px;
        background-color: #e23b44;
        border: 1px solid #e23b44;
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 240% */
        letter-spacing: 0.5px;
    }
}

.connect-calendar-card.disabled {
    background-color: #f4f4f4;
}

.connected-info {
    display: flex;
    height: 31.72px;
    padding: 0px 4px;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    border: 1px solid #133f7d;
    color: #133f7d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.m-150 {
    margin-left: 150px;
}

.cursor-auto {
    cursor: auto;
}

.jd-sidebar {
    width: 50% !important;
    max-width: 525px;
}
.ats-integration-container {
    .permission-toggle-switch {
        margin-right: 40px;
        margin-top: -4px;
    }
    .sso-margin {
        margin: 5px 0px;
    }
    .btn-save-cancel {
        margin: 10px 0px;
        justify-content: center;
        gap: 10px;
    }
    .btn-sso {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 116px;
        height: 36px;
        padding: 10px;
        border-radius: 5px;
        line-height: 15px;
    }
    .btn-save-sso {
        border: none;
        background: #1c4784;
        color: #fff;
    }
    .btn-cancel-sso {
        border: 1px solid #1c4784;
        background: #fff;
        color: #1c4784;
    }
    .sso-div {
        cursor: pointer;
        width: 100%;
    }
}

.reset-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;
    .reset-clear-popup {
        display: flex;
        justify-content: center;
        background-color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        width: 400px;
        .close-btn {
            width: 12px;
            height: 12px;
            color: #667085;
            justify-content: center;
            align-items: center;
        }
        .reset-popup-title {
            font-size: 18px;
            text-align: center;
            color: #133f7d;
            margin-bottom: 25px;
        }
        .reset-popup-btn-container {
            gap: 12px;
            .cpb {
                border: none;
                border-radius: 8px !important;
                font-size: 14px;
                padding: 32px, 0px, 0px, 0px;
                color: black;
                width: 100%;
                height: 44px;
            }
            .cpb-white {
                background-color: #ffffff;
                color: black;
                border-color: #d0d5dd;
                border: 1px solid;
            }
            .cpb-red {
                background-color: #d92d20;
                color: white;
            }
            .cpb-green {
                background-color: #18ac00;
                color: white;
                font-size: 16px;
                width: 50%;
            }
        }
    }
}

.more-fields {
    color: #0d6efd;
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
    display: inline-block;
    margin: 10px 15px 10px 0px;
    cursor: pointer;
    width: auto;
}

.z-high {
    z-index: 2000;
}

.overflow-unset {
    overflow: unset;
}
.gap-5p {
    gap: 5px;
}

.z-index-1 {
    z-index: 1;
}
.status-completed {
    border-color: #18ac00;
    color: #18ac00;
}

.status-invitesent {
    border-color: #133f7d;
    color: #133f7d;
}

.status-incompletedata {
    border-color: #f2464b;
    color: #f2464b;
}
.position-popup-btn {
    display: flex;
    width: 160px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.position-white-mode {
    border: 1px solid #d0d5dd;
    background: #fff;
}
.position-blue-mode {
    background: #133f7d;
    color: #fff;
    border: none;
}
.main-popup .cpp-input-container {
    width: 100% !important;
}
.applicant-code {
    color: #83879e;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0px 0px 0px;
}
.resume-btn {
    padding: 6px 10px;
}
.applicant-m-left {
    margin-left: 5px;
}
.applicant-white-btn {
    width: 130px;
    border: 1px solid #133f7d !important;
    color: #133f7d !important;
    background: #fff !important;
}
.applicant-btn {
    width: 130px;
    margin-right: 20px;
}
.invited-filter {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    margin: 0px !important;

    .invited-date-filter {
        margin-right: 0px;
        width: 85px !important;
        padding: 5px;
        font-size: 10px;
        border-radius: 2px;
        border: 0.5px solid #a9a9ab !important;
    }
}
.to-from-font {
    font-size: 8px;
    color: #626262;
    margin-bottom: 0px;
}
.applicant-m-left {
    margin-left: 5px;
}
.info-container {
    .info-status {
        font-size: 16px;
        font-weight: 600;
        line-height: 24.59px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .status {
            font-size: 12px;
            height: 25px;
            width: 100%;
            padding: 0.3em 1em !important;
            display: flex;
            align-items: center;
            color: $White-Color;
            border-radius: 0px 5px 5px 0px;
            justify-content: center;
        }
        .pending,
        .invited,
        .reinvited {
            background: #9b9b9b;
            max-width: 80px;
        }
        .canceled {
            background: #f2464b;
            max-width: 80px;
        }
        .recommended {
            background: #18ac00;
        }
        .cautiously-recommended {
            background: #e8aa4e;
        }
        .not-recommended {
            background: #8b0000;
        }
        .position {
            background: #0086d2;
            max-width: 141px;
        }
        .invitesent {
            background: #133f7d;
            max-width: 100px;
        }
    }
}
.main-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .interview-popup-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: #1d1d1df2;
        margin-bottom: 15px;
    }

    .duration_img {
        max-width: 200px;
        width: 100%;
        margin: 10px auto;
    }

    .duration_content_container p {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        font-weight: 400;
        color: #000000f2;
        text-align: center;
    }

    .confirm-main-box {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        background-color: $White-Color;
        width: 500px;

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }
    }

    .time-slot-container {
        width: 530px;
        height: 500px;
        background-color: $Back-ground;
        border-radius: 10px;

        .timeslot-close {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 20px;
            right: 10px;
            border-radius: 50%;
            background-color: $theme-blue;
            border: none;

            .white-close {
                height: 10px;
                width: auto;
            }
        }

        .slot-card-container {
            height: 90%;
            overflow-y: auto;

            .slot-card {
                background-color: $theme-light-blue;
                border-radius: 10px;

                .slots-container {
                    display: flex;
                    flex-wrap: wrap;

                    .time-container {
                        border: 1.5px solid #becada;
                        padding: 5px;
                        color: $theme-blue;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.status-invitesent {
    border-color: #133f7d;
    color: #133f7d;
    border: 1px solid;
}

.invitation-header-title {
    background-color: #ebedf9;
    height: 105px;

    p {
        font-size: 24px;
        font-weight: 700;
        line-height: 32.78px;
        text-align: center;
        color: $theme-blue;
        max-width: 341px;
        width: 100%;
    }
}

.expiry-btn-txt {
    font-size: 23px !important;
    hyphens: none !important;
}

.expiry-btn {
    background-size: 10px !important;
    height: 40px !important;
    width: 150px !important;
}

.common-btn.linkBtn {
    background-color: $theme-peacock;
    border-color: $theme-peacock;
}
.text-blue-side {
    color: #04c;
    cursor: pointer;
}

.dashboard-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 880px;
    margin: 0 auto;
    @media screen and (max-width: 1460px) {
        width: 720px;
    }
}

.dashboard-content-big {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1080px;
    margin: 0px auto;
    @media screen and (max-width: 1460px) {
        width: 920px;
    }
}

.dp-progresscard-left-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dp-progresscard-bottom-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.funnel-chart-big {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    width: 810px;
    margin: 0 auto;
    .percentage {
        background-color: #6c757d;
        color: white;
        padding: 5px 10px;
        font-size: 14px;
        position: absolute;
        transform: rotate(180deg);
        clip-path: polygon(50% 0%, 100% 40%, 100% 100%, 0% 100%, 0% 40%);
        height: 35px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1; /* Ensures the content is above the border */
    }
    .pre-rotate {
        transform: rotate(180deg);
        margin-top: 6px;
    }
}

.funnel-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 660px;
    margin: 0 auto;
    .percentage {
        background-color: #6c757d;
        color: white;
        padding: 5px 10px;
        font-size: 14px;
        position: absolute;
        transform: rotate(180deg);
        clip-path: polygon(50% 0%, 100% 40%, 100% 100%, 0% 100%, 0% 40%);
        height: 35px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1; /* Ensures the content is above the border */
    }
    .pre-rotate {
        transform: rotate(180deg);
        margin-top: 6px;
    }
}

.funnel-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.funnel-stage {
    background-color: #5586a5; /* Teal color */
    color: white; /* Text color */
    width: 64%; /* Adjust width as needed */
    height: 110px; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 5% 100%); /* Creates the trapezoid shape */
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: 2px auto;
}

.funnel-stage:nth-child(2) {
    background-color: #6ba5a5;
    width: 80%;
}

.funnel-stage:nth-child(4) {
    background-color: #135b8b;
    width: 72%;
}

.funnel-stage .funnel-count {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
}

.funnel-stage .funnel-label {
    font-size: 18px;
    font-weight: 200;
    margin-top: -5px;
}
.applicant-cancel-btn {
    background-color: #ffffff !important;
    border: 1.5px solid #123a75 !important;
    color: #123a75 !important;
}
.popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    .invite-bt {
        background-color: #172b4d;
        color: #ffffff;
        width: 180px;
        height: 100px;
        font-size: 20px;
    }

    .popup-box {
        height: auto;
        background-color: #fff;
        border-radius: 1em;
        padding: 2em;

        h6 {
            color: #0c0c0d;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .title {
            line-height: 1;
            h3 {
                margin: 0;
            }
            .close-btn {
                img {
                    height: 1em;
                }
            }
        }
        .input-container {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 400px;
            overflow-y: auto;
            padding-right: 10px;
            .input-field {
                margin: 1% 0;
            }
            .textarea {
                width: 100%;
            }
            .btn-container {
                margin-top: 2em;
            }
        }
    }
}

.custom-accordion .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: transparent;
    box-shadow: none;
}

.custom-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.125);
}

.invitation-header-title {
    background-color: #ebedf9;
    height: 105px;

    p {
        font-size: 24px;
        font-weight: 700;
        line-height: 32.78px;
        text-align: center;
        color: $theme-blue;
        max-width: 341px;
        width: 100%;
    }
}

.expiry-btn-txt {
    font-size: 23px !important;
    hyphens: none !important;
}

.expiry-btn {
    background-size: 10px !important;
    height: 40px !important;
    width: 150px !important;
}

.common-btn.linkBtn {
    background-color: $theme-peacock;
    border-color: $theme-peacock;
}

.interview-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    .loader {
        font-size: 4px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        margin-left: 10px;
        animation: mulShdSpin 1.3s infinite linear;
        transform: translateZ(0);
    }
    .scheduled-load {
        color: #ff822e;
    }
    .pending-load {
        color: #626262;
    }
    @keyframes mulShdSpin {
        0%,
        100% {
            box-shadow:
                0 -1.5em 0 0.1em,
                1em -1em 0 0em,
                1.5em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.5em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.5em 0 0 -0.5em,
                -1em -1em 0 0em;
        }
        12.5% {
            box-shadow:
                0 -1.5em 0 0,
                1em -1em 0 0.1em,
                1.5em 0 0 0,
                1em 1em 0 -0.5em,
                0 1.5em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.5em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }
        25% {
            box-shadow:
                0 -1.5em 0 -0.25em,
                1em -1em 0 0,
                1.5em 0 0 0.1em,
                1em 1em 0 0,
                0 1.5em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.5em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }
        37.5% {
            box-shadow:
                0 -1.5em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.5em 0 0 0,
                1em 1em 0 0.1em,
                0 1.5em 0 0,
                -1em 1em 0 -0.5em,
                -1.5em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }
        50% {
            box-shadow:
                0 -1.5em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.5em 0 0 -0.5em,
                1em 1em 0 0,
                0 1.5em 0 0.1em,
                -1em 1em 0 0,
                -1.5em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }
        62.5% {
            box-shadow:
                0 -1.5em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.5em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.5em 0 0,
                -1em 1em 0 0.1em,
                -1.5em 0 0 0,
                -1em -1em 0 -0.5em;
        }
        75% {
            box-shadow:
                0 -1.5em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.5em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.5em 0 -0.5em,
                -1em 1em 0 0,
                -1.5em 0 0 0.1em,
                -1em -1em 0 0;
        }
        87.5% {
            box-shadow:
                0 -1.5em 0 0,
                1em -1em 0 -0.5em,
                1.5em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.5em 0 -0.5em,
                -1em 1em 0 0,
                -1.5em 0 0 0,
                -1em -1em 0 0.1em;
        }
    }
}
