.achievement {
    background-color: $theme-light-peacock;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 100px 10px;

    .info-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
            z-index: 1;
        }
        .coma {
            position: absolute;
            width: 8em;
            left: 0;
            bottom: 60%;
            z-index: 0;
        }
    }

    .achieve-bg {
        width: 45%;
        @media screen and (max-width: 992px) {
            width: 90%;
            margin-top: 5%;
        }
    }
}
