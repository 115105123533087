.report-page {
    height: auto;
    padding-bottom: 2em;
    background-color: #f6f7fb;
    position: relative;
    .div-line {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .main-img {
        width: 100%;
    }

    .content-box {
        padding: 0 40px;
    }

    .report-title {
        margin: 1em 0;
        font-size: 60px;
    }
    .candi-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;

        .candi-details {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: #626262;
            font-size: 18px;
            .candi-name {
                color: #133f7d;
            }
        }
        .candi-status {
            width: 260px;
        }
    }
    .candi-score {
        text-align: center;
        font-weight: 600;
        .score-chart-container {
            width: 250px;
            height: auto;
            .score-no {
                font-size: 60px;
            }
        }
    }
    .top-skill-container {
        margin: 2em 0;
    }
    .skill-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .skill-card-container {
            margin: 2%;
            border-radius: 15px;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            background-color: $White-Color;
            .skill-card {
                background-color: #f8fdfd;
                padding: 8px;
                margin: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                .chart {
                    text-align: center;
                    width: 70px;
                    height: auto;
                }
                .chart-details {
                    margin: 0 1em;
                    .skill-name {
                        width: 140px;
                    }
                    .dot-container {
                        display: flex;
                        align-items: center;
                        .status-dot {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .rating-info {
        .title {
            text-align: center;
            margin: 1em 0;
        }
        .rating {
            display: flex;
            align-items: center;
            font-weight: 600;
            margin: 1em 0;
        }
        .r-dot {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    h4 {
        font-size: 1.6em;
        font-weight: 600;
        margin-bottom: 1em;
    }
    h6 {
        font-size: 1em;
        margin-bottom: 1em;
    }
    .skills-description {
        font-size: 1em;
        padding: 2%;
        background-color: $White-Color;
        margin-bottom: 1em;
    }
    .code-container {
        color: $White-Color;
        border-radius: 0.5em;
        padding: 1em;
    }

    .report-print {
        border: none;
        background-color: $theme-peacock;
        padding: 10px 20px;
        color: $White-Color;
        position: absolute;
        top: 50px;
        right: 50px;
    }
}

@media print {
    .report-page {
        background-color: #abadb7;
        .bargraph-container {
            width: 500px;
        }
        .report-print {
            display: none;
        }
    }
}
