.company-info {
    background-color: $theme-peacock;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    padding: 50px 0;

    .info-box {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $White-Color;
        .title {
            text-align: center;
        }
        .subtitle {
            margin-top: 10px;
            margin-bottom: 50px;
            text-align: justify;
        }
        .sign-up-now {
            @media screen and (max-width: 598px) {
                text-align: center;
            }
        }
        .signin-btn {
            padding: 0.5em 1em;
            margin-top: 10px;
            img {
                height: 1em;
                margin-right: 10px;
            }
            @media screen and (max-width: 598px) {
                margin: 10px auto;
            }
        }
    }

    .bg-img {
        width: 40%;
    }

    .info-box,
    .bg-img {
        @media screen and (max-width: 992px) {
            width: 80%;
            margin: 10px 0;
        }
    }
}
