.subuser-tab-container {
    .tab {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding-bottom: 10px;
        box-sizing: border-box;
    }
    .active {
        border-bottom: 3px solid;
    }
}

.subuser-list-container {
    height: auto;
}

.subuser-table-header {
    background-color: $theme-light-blue;
    height: 3em;
    color: $theme-subuser;
    min-width: 900px;
}
.subuser-header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
}
.two-arrow {
    height: 0.6em;
    margin-left: 5px;
    cursor: pointer;
}

.subuser-tabel-content {
    min-width: 900px;
    height: auto;
    text-align: center;
}
.subuser-content {
    padding: 15px 0;
    background-color: $White-Color;
}

.sh-permission,
.sh-rmail {
    color: $theme-blue;
    text-decoration: underline;
    text-decoration-color: $theme-blue;
    cursor: pointer;
}

.sh-serial,
.sh-name,
.sh-status,
.sh-permission,
.sh-menu {
    display: flex;
    align-items: center;
    height: 100%;
}

.sh-status-active {
    color: $theme-light-green;
}
.sh-status-inactive {
    color: $theme-red;
}

.sc-container {
    .add-role-sub-btn {
        padding: 5px 10px;
        box-sizing: border-box;
        height: 35px;
        img {
            margin-right: 5px;
        }
    }
    @media screen and (max-width: 992px) {
        width: 100%;
    }

    @media screen and (max-width: 992px) {
        button {
            margin-top: 1em;
        }
    }
}
