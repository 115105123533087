.why-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;

    .card-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .wu-card {
            width: 45%;
            padding: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 8px 7px 20px rgba(0, 0, 0, 0.25);
            border-radius: 0.4em;
            margin: 2.5%;
            .card-img {
                width: 5em;
                margin-right: 30px;
            }
            @media screen and (max-width: 992px) {
                width: 90%;
            }
            @media screen and (max-width: 598px) {
                flex-direction: column;
                text-align: center;
                .card-img {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
