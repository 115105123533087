.demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $theme-light-peacock;
    text-align: center;
    padding: 50px 10px;
    .subtitle {
        width: 50%;
        @media screen and (max-width: 992px) {
            width: 80%;
        }
    }
    .calender-bg-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 50px 0 0 0;
        width: 100%;

        // .background-img {
        //   width: 50%;
        //   @media screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        .map-container {
            width: 40%;
            margin: 15px 0;
            @media screen and (max-width: 992px) {
                width: 85%;
                margin-top: 5%;
            }
            .address,
            .contact,
            .mail {
                display: flex;
                width: 80%;
                color: #35363a;
                margin: 20px 0;
                text-align: justify;
                img {
                    margin: 0 20px;
                    width: 1.5em;
                    height: 1.5em;
                }
                .location {
                    margin-top: 0.3em;
                }
            }
        }
        .meetings-iframe-container {
            width: 40%;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 992px) {
                width: 100%;
                margin-top: 5%;
            }
        }
    }
}
