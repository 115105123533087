.logo-container {
    height: 10%;
}
.error-content-wrapper {
    .error-title,
    .error-subtitle {
        color: $theme-blue;
    }
    .error-img {
        width: 38%;
        @media screen and (max-width: 992px) {
            width: 80%;
        }
    }
    .home-btn {
        height: 2.8em;
        width: 12em;
    }
}
