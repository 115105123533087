// Color
$Back-ground: #f6f7fb;
$White-Color: #ffffff;
$Black-Color: #000000;
$theme-black: #0c0c0d;
$theme-light-gray: #c5c5c5;
$theme-blue: #123a75;
$theme-border: #a9a9ab;
$theme-placeholder: #8780ab;
$theme-error: #ed184f;
$theme-light-red: #f8e6e6;
$theme-mercury: #f6f7fb;
$theme-teal: #11b2b8;
$theme-light-chart: #e7ebfb;
$theme-subtitle-color: #6f6f84;
$theme-popup-bg: rgba(0, 0, 0, 0.5);
$theme-subscription-type: #4471b0;
$theme-lavender: #8780ab;
$theme-arrow: #626262;
$theme-good: #56a66a;

// $theme-error: #F8E6E6;
$theme-light-blue: #e6e9f8;
$theme-peacock: #12b6bc;
$theme-red: #d80707;
$theme-green: #18652e;
$theme-yellow: #e8aa4e;
$theme-carrot: #fe8471;
$theme-light-green: #00c851;
$theme-google: #ebebeb;
$theme-subuser: #6f6f84;
$theme-orange: #ff9f43;
$theme-light-oragne: #ffecd9;
$theme-green-background: #effcf4;
$theme-light-peacock: #d5fafc;
$theme-bg: #f6f7fb;
$theme-mist: #476ea5;
$theme-gray: #626262;
