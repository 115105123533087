.retention {
    background-color: $theme-light-peacock;
    padding: 50px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    .content {
        width: 50%;
        .subtitle {
            text-align: justify;
        }
        @media screen and (max-width: 992px) {
            width: 80%;
            margin: 20px 0;
        }
    }

    .retention-bg {
        width: 40%;
        @media screen and (max-width: 992px) {
            width: 80%;
        }
    }
}
