// my Positions
.my-position-main-container {
    background-color: $theme-mercury;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
        padding: 1em 1em;
    }
    @media screen and (min-width: 1900px) {
        padding: 3em 3.5em;
    }
}

.pp-divider {
    border-bottom: 1.5px solid $theme-gray;
}

.myPositionTitle {
    color: $theme-blue;

    @media screen and (max-width: 992px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 1em;
    }
}

.myPositionContainer {
    @media screen and (max-width: 992px) {
        max-width: 100%;
    }
}

.position-main-container {
    height: 100%;
    .position-card-container {
        flex: 1;
        height: 100%;
        overflow-x: auto;
        .position-table-header {
            background-color: $theme-light-blue;
            color: $theme-gray;
            padding: 15px 10px;
            display: flex;
            align-items: center;
            height: 60px;
            min-width: 1140px;
            .double-arrow {
                height: 0.7em;
                margin-left: 10px;
            }
        }
        .position-table-row-container {
            min-width: 1140px;
            .position-table-row {
                background-color: $White-Color;
                padding: 15px 0;
                display: flex;
                align-items: center;
                min-height: 60px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                .position-table-row-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                .jd-download-btn {
                    background-color: $theme-blue;
                    color: white;
                    border: none;
                    padding: 5px 10px;
                }
            }
        }
    }
    .card-image {
        height: 1.2em;
    }
    .info-container {
        position: fixed;
        background-color: #fff;
        height: calc(100% - 60px);
        box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
        right: 0;
        padding: 0 12px 12px;
        transition: all 0.5s ease-out;
        z-index: 10000;
        top: 60px;
        overflow-y: auto;
        background-color: $White-Color;
        @media screen and (min-width: 993px) {
            width: 43%;
        }
        @media screen and (max-width: 992px) {
            width: 100%;
        }

        .list {
            background-color: $White-Color;
            .title {
                font-size: 14px;
                padding-left: 10px;
                @media screen and (max-width: 480px) {
                    width: 480px;
                }
                .btn-close {
                    height: 0.4em;
                    width: 0.4em;
                }
            }
            .table-list {
                width: 100%;
                text-align: start;
                @media screen and (max-width: 480px) {
                    width: 480px;
                }
                td {
                    padding: 1em;
                }
                .tl-header {
                    td {
                        color: $theme-subuser;
                    }
                }
                .tl-row {
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    .list-id,
                    .view-more {
                        padding: 5px;
                        background-color: $theme-blue;
                        border-radius: 0.4em;
                        color: $White-Color;

                        img {
                            height: 0.8em;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .my-position-feedback {
            background-color: $White-Color;
            .back-close {
                padding-left: 10px;
            }
            .status-box {
                .status {
                    color: $White-Color;
                    background-color: $theme-green;
                    background-color: #00c851;
                    padding: 0.3em 1em;
                    border-top-right-radius: 0.3em;
                    border-bottom-right-radius: 0.3em;
                }
            }
            .fb-btn {
                padding: 0.6em 1em;
                border-radius: 0.5em;
                img {
                    height: 1em;
                    margin: 0 5px;
                }
            }
            .candi-name,
            .list-title {
                color: #83879e;
            }
            .list-info {
                color: #83879e;
                font-weight: 500;
            }
            .divider {
                height: 1px;
                background-color: $theme-border;
            }
        }
    }
}
.width-55 {
    width: 55%;
}
.cand-id,
.candi-name,
.list-title {
    color: #83879e;
}
.list-info {
    color: #83879e;
    font-weight: 500;
}

.create-int-btn {
    position: absolute;
    border: none;
    background-color: $theme-blue;
    color: $White-Color;
    top: 48px;
    right: 10px;
    padding: 5px 10px;
    img {
        margin-right: 5px;
    }
}

.jd-img {
    height: 1.2em;
}

.boldHead {
    font-weight: bold;
}

.input-edit-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    width: 26px;
    cursor: pointer;
}

.sort-by {
    color: $theme-blue;
}

.sort-by-box {
    width: 25%;
    @media screen and (max-width: 992px) {
        width: 40%;
    }
    @media screen and (max-width: 580px) {
        width: 80%;
    }
}

.skip-btn {
    border: 1.4px solid $theme-blue;
    background-color: transparent;
    color: $theme-blue;
    padding: 7px;
    .skip-btn-img {
        height: 10px;
        width: auto;
        margin-left: 5px;
    }
}
