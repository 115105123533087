.signup-main {
    display: flex;
    height: 100%;
    width: 100%;
    // justify-content: center;
    // align-items: center;
    .highlight {
        text-decoration: none;
        font-weight: 700;
        color: $theme-blue;
    }
}

.signup-left {
    display: flex;
    flex-basis: 30%;
    width: 100%;
    min-width: 30%;
    transition: 0.3s all linear;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.signup-right {
    padding-left: 3em;
    padding-right: 3em;
    width: 100%;
    background-color: $Back-ground;

    @media screen and (min-width: 1000px) {
        overflow-y: scroll;
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $theme-blue;
            border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: $theme-blue;
        }
    }

    @media screen and (min-width: 923px) {
        justify-content: center;
    }

    @media screen and (max-width: 923px) {
        padding-left: 1em;
        padding-right: 1em;
        padding: 1em;
    }
}

.socialContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 1em;

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
}

.signupInputContainer {
    padding: 0.4em 1em;
    padding-left: 2em;
    padding-right: 2em;

    @media screen and (max-width: 769px) {
        padding-left: 0.6em;
        padding-right: 0.6em;
    }
}

.signupFormContainer {
    padding: 0 3em;

    @media screen and (max-width: 922px) {
        padding: 0 1em;
    }
}

.password-tc-box {
    padding: 1em;
    width: 60%;
    background-color: $White-Color;
    top: 105%;
    left: 0;
    z-index: 999;
    .ptc-subtitle {
        color: $theme-placeholder;
        .ptc-img {
            height: 0.8em;
            margin-right: 5px;
        }
    }
}

.radio-color-blue {
    color: $theme-blue;
}

.radio-color-grey {
    color: $theme-subtitle-color;
}

.user-type-radio-container {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.2em;
    margin: 0 auto;
    visibility: hidden;

    /* The radio-container */
    .radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-family: 'Manrope-SemiBold';
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
    }

    .radio-container:hover input ~ .checkmark {
        background-color: $White-Color;
    }

    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $White-Color;
        border: 1px solid $theme-border;
        border-radius: 50%;

        &:after {
            content: '';
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #4e6f9d;
            border: 1px solid #4e6f9d;
        }
    }

    .radio-container .radio-container input:checked ~ .checkmark {
        background-color: $White-Color;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-container input:checked ~ .checkmark:after {
        display: block;
    }
}
