.react-calendar__tile--active {
    background: #8780ab !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px !important;
}
.react-calendar__month-view__days__day {
    border: 1px solid #f6f7fb !important;
}
.react-calendar {
    padding: 10px;
    background: #e6e9f8;
}
.react-calendar button {
    background: #fff;
}
.react-calendar button abbr {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #9b9b9b;
}
.react-calendar__navigation__label {
    background: transparent !important;
}
.react-calendar__navigation__label__labelText {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #1c4784;
}
.react-calendar__month-view__weekdays__weekday abbr {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #121314;
    text-decoration: none;
}
.react-calendar__navigation__arrow {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12) !important;
    border-radius: 6px;
    height: 20px;
    margin: 12px 3px !important;
}
.react-calendar__tile--active abbr {
    color: #fff !important;
}
