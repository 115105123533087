.cookies-popup {
    position: fixed;
    bottom: 0;
    left: 25%;
    width: 50%;
    z-index: 2;
    background-color: $theme-blue;
    display: flex;
    flex-direction: column;
    color: $White-Color;
    padding: 20px 20px 0 20px;
    font-size: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media screen and (max-width: 992px) {
        width: 100%;
        left: 0;
        border-radius: 0;
    }
    .cookies-description {
        text-align: center;
    }
    .cookies-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .cookies-btn {
            border-style: none;
            border-radius: 0.4em;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2em;
            width: 6em;
            color: $White-Color;
            margin: 10px 20px;
        }
        .blue {
            background-color: #0378b5;
        }
        .red {
            background-color: #dc3545;
        }
    }
}
