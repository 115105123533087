.filter-btn {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: border-box;
    border: 1.5px solid $theme-blue;
}

.filter-img {
    height: 60%;
    width: auto;
}

.filter-clear-btn {
    text-align: center;
    font-size: 10px;
    margin: 5px 0px;
    color: #1f407e;
    background-color: transparent;
    border: none;
}
.convert-btn-popup {
    top: 110%;
    right: 110%;
    background-color: $White-Color;
    width: 10em;
    height: auto;
    padding: 10px;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    .view-option {
        color: $theme-blue;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background-color: $theme-light-blue;
        }
        .view-option-img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
        }
    }
}
.filter-btn-popup {
    top: 110%;
    right: 110%;
    background-color: $White-Color;
    width: 10em;
    height: 21em;
    padding: 20% 40%;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .date-container {
        label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
                color: $theme-border;
                margin: 5% 0;
            }
            input {
                border-style: none;
                color: $theme-subuser;
                outline: none;
                border-bottom: 1px solid $theme-border;
                &::-webkit-calendar-picker-indicator {
                    background-image: url('../../images/calender.svg');
                }
            }
        }
    }

    .title {
        margin: 5% 0;
        color: $theme-border;
    }

    label {
        margin-bottom: 2%;
        color: $theme-subuser;
        input {
            margin-right: 5%;
        }
    }
    .subuser-dropdown-container {
        .subuser-dropdown-title {
            color: $theme-border;
        }
        .subuser-dropdown {
            .css-13cymwt-control {
                border: 1.2px solid $theme-border;
            }
            .css-t3ipsp-control {
                border: 1.2px solid $theme-border;
                box-shadow: none !important;
            }
            .css-1xc3v61-indicatorContainer,
            .css-15lsz6c-indicatorContainer {
                padding: 0 5px !important;
            }
        }
    }
}
.range-slider {
    position: relative;
}

.rs-label {
    position: relative;
    top: 0;
    background-image: url('../../images/droplet.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px 28px;
    height: 28px;
    width: 28px;
    color: $White-Color;
}
.box-minmax {
    width: 100%;
    margin: 1% auto;
    display: flex;
    justify-content: space-between;
    color: $theme-black;
}
.rs-range {
    display: flex;
    align-items: center;
    appearance: none;
    background: none;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    height: 20px;
    width: 90%;
    margin: 0 auto;

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        background: $theme-placeholder;
        height: 1.5px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: $theme-blue;
        height: 10px;
        width: 10px;
        margin-top: -5px;
        border-radius: 50%;
    }

    &::-moz-range-track {
        background: $theme-placeholder;
        height: 1.5px;
    }

    &::-moz-range-thumb {
        background: $theme-blue;
        border-radius: 50%;
        width: 10px;
        height: 10px;
    }
    &::-webkit-progress-bar {
        height: 2px;
        background-color: $theme-blue;
    }
    &::-moz-progress-bar {
        height: 2px;
        background-color: $theme-blue;
    }
}
