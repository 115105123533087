.h-90 {
    height: 93.5%;
}

//search select in graph css
.ui-searhselect-container {
    width: 20%;
}

.side-panel-container {
    width: 25%;

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.side-panel {
    position: relative;
    width: 100%;

    ul {
        padding-right: 1em;

        li {
            list-style-type: none;
            color: $theme-subuser;
            // height: 2.4em;
            padding: 10px;
            border-radius: 0.3em;

            img {
                width: 1em;
            }

            .arrow {
                height: 0.8em;
                width: 0.8em;
            }

            &:hover {
                background-color: $White-Color;
                color: $theme-blue;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
            }
        }

        .active {
            background-color: $White-Color;
            color: $theme-blue;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
    }
}

.userinfo-profile-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 131px;
    width: 131px;
    border-radius: 50%;
    border: 2px solid #133f7d;

    .company-logo-container {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .user-profile-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: auto;
        }
    }

    .edit-profile-icon {
        position: absolute;
        bottom: 0;
        right: 10px;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: $theme-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.company-logo-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;

    .user-profile-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100px;
        object-fit: cover;
        object-position: top center;
    }

    .edit-profile-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: $theme-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.userInfoForm {
    display: flex;

    @media screen and (max-width: 993px) {
        flex-direction: column;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .formPart-1 {
        width: 75%;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    .formPart-2 {
        width: 25%;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 992px) {
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            margin: 25px 0;
        }

        .rating-container {
            @media screen and (max-width: 992px) {
                margin-left: 50px;
            }

            .rating-box {
                text-align: center;

                @media screen and (max-width: 992px) {
                    text-align: start;
                }

                img {
                    height: 1em;
                    margin: 0 2.5px;
                }
            }
        }
    }
}

.userinfo-userdetail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.my-profile {
    background-color: $theme-mercury;
    height: 100%;
    width: 100%;

    .back-arrow {
        height: 1em;
    }

    padding: 1.8em 2.2em 0 2.2em;

    @media screen and (max-width: 992px) {
        padding: 1em 1em;
        display: block;
    }

    @media screen and (min-width: 1900px) {
        padding: 3em 3.5em 0 3.5em;
    }
}

.myProfileFormContainer {
    width: 80%;
    padding-left: 2em;
    border-left: 1.5px solid $theme-light-gray;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
    }

    @media screen and (max-width: 992px) {
        padding: 0;
        border: none;
    }

    .profile-btn {
        padding: 0.6em 2em;
    }

    .user-id {
        color: $theme-placeholder;
    }

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.ui-input-container {
    width: 45%;

    @media screen and (max-width: 580px) {
        width: 100%;
    }
}

.ui-btn-container {
    margin-top: 40px;

    @media screen and (max-width: 580px) {
        margin: 40px 0;
    }
}

.blue-class > svg > path {
    fill: blue;
}

.subcription-title {
    color: $theme-blue;
}

.subcription-subtitle {
    color: $theme-subtitle-color;

    @media screen and (max-width: 992px) {
        flex-wrap: wrap;

        button {
            margin-top: 10px;
        }
    }

    .right-arrow-grey {
        height: 0.7em;
    }
}

.subscription-card-container {
    @media screen and (min-width: 993px) {
        padding-right: 20px;
        height: 85%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $theme-blue;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $theme-blue;
        }
    }
}

.subcard-name {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    background-color: $White-Color;
    padding: 0.5em 1.2em;

    .subcard-logo {
        margin-right: 5px;
        height: 1em;
    }
}

.subscription-type {
    color: $White-Color;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    padding: 0.5em 1.2em;
}

.active-subscription {
    background-color: $theme-subscription-type;
}

.inactive-subscription {
    background-color: $theme-carrot;
}

.subcard {
    background-color: $White-Color;
    padding: 0.8em 1em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }

    .part-1 {
        width: 75%;

        @media screen and (max-width: 992px) {
            width: 100%;
        }

        .subcard-price {
            color: $theme-blue;
            margin-left: 10px;
            font-weight: 900;
        }

        .subcard-line {
            height: 0.5px;
            background-color: $theme-placeholder;
            margin: 10px 0 20px 0;

            @media screen and (max-width: 992px) {
                margin: 10px 0;
            }
        }

        .subcard-detail-box {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            @media screen and (max-width: 992px) {
                display: none;
            }
        }

        .subcard-detail {
            width: 30%;
            display: flex;
            align-items: center;
            color: $theme-placeholder;
            margin-left: 10px;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .grey-ring {
                height: 1.2em;
                margin-right: 5px;
            }
        }
    }

    .part-2 {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        @media screen and (max-width: 992px) {
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        .blue-dot {
            height: 0.9em;
            margin-right: 5px;
        }

        .subcard-status {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media screen and (max-width: 992px) {
                width: auto;
            }
        }

        .subcard-period {
            margin: 10px 0;
            font-weight: 500;
            width: 100%;
            text-align: end;

            @media screen and (max-width: 992px) {
                // width: auto;
                text-align: initial;
                margin-left: 10px;
            }
        }

        .invoice-img {
            height: 2em;
            cursor: pointer;
        }

        .cancel {
            color: $theme-error;
            cursor: pointer;
            border: none;
            background: transparent;
            text-align: left;
        }

        .upload-white-label {
            color: $theme-blue;
            cursor: pointer;
        }
    }
}

.empty-card-cotainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $theme-subtitle-color;

    .empty-logo {
        height: 10em;
    }

    .ecc-btn {
        padding: 0.6em 2em;
    }
}

.transaction-table {
    @media screen and (min-width: 993px) {
        height: 85%;
    }
}

.transaction-row-container {
    height: 80%;
    padding-right: 10px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
    }

    @media screen and (max-width: 992px) {
        width: 992px;
    }
}

.transaction-row {
    display: flex;

    @media screen and (max-width: 992px) {
        width: 992px;
    }
}

.role-row {
    color: #5a5a5a;
}

.thr-content {
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &.role-content-head {
        padding-left: 28px;
    }

    .two-arrow {
        height: 0.6em;
    }

    .download {
        border-style: none;
        background-color: $theme-green-background;
        color: $theme-light-green;
        padding: 0.5em 1em;
        border-radius: 0.4em;
    }
}

.header-row {
    background-color: $theme-light-blue;
    color: $theme-subuser;
    margin-right: 10px;
}

.thr-15 {
    width: 15%;
}

.thr-40 {
    width: 40%;
}

.thr-20 {
    width: 20%;
}

.view-down {
    color: $theme-blue;
    cursor: pointer;

    .view-img {
        height: 1em;
        margin: 0 5px;
    }
}

.tran-status {
    color: $White-Color;
    height: 2em;
    width: 8em;
    border-radius: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tran-status-red {
    background-color: $theme-error;
}

.tran-status-green {
    background-color: $theme-light-green;
}

.tran-status-grey {
    background-color: $theme-subtitle-color;
}

.payment-btn {
    padding: 0.6em 2em;
}

.pay-content {
    display: flex;
    align-items: center;
    padding: 10px;
}

.payment-delete {
    width: 30px;
}

//invoice css//
.invoice-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
    overflow-y: auto;

    .invoice {
        position: absolute;
        top: 0;
        width: 595px;
        background-color: white;
        padding: 25px 20px 0px 20px;
        display: flex;
        flex-direction: column;

        .invoice-close {
            position: absolute;
            top: 1%;
            right: 1%;
            height: 5px;
            width: 5px;
        }

        .grey-text {
            color: $theme-subuser;
        }

        .tr-border {
            border-bottom: 0.5px solid #6f6f84;
        }

        .invoice-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            color: #6f6f84;

            .invoice-logo {
                width: 170px;
                height: 80px;
            }

            .company-name {
                margin: 0;
            }

            .company-address {
                display: flex;
                flex-direction: column;
                text-align: end;

                * {
                    margin: 0;
                }
            }
        }

        .invoice-main-content {
            .company-details {
                border-bottom: 0.5px solid $theme-subuser;
                padding-bottom: 10px;
                margin-bottom: 10px;

                * {
                    margin: 0;
                }

                display: flex;

                .part-title {
                    color: #6f6f84;
                    font-size: 14px;
                }

                .part-1 {
                    width: 35%;
                }

                .part-2 {
                    width: 35%;
                    text-align: end;
                }
            }

            .product-details {
                margin-bottom: 30px;

                span,
                .invoice-table-header {
                    color: $theme-subuser;
                }

                td {
                    padding: 5px 0;
                }
            }

            .total-container {
                table {
                    width: 35%;

                    td {
                        padding: 2px 0;
                    }
                }
            }
        }

        .invoice-table-header {
            color: #6f6f84;
        }

        .additional-info {
            color: #6f6f84;
        }

        .auth {
            color: #6f6f84;
            margin-top: 20px;
        }

        .invoice-footer {
            * {
                margin: 0;
            }

            color: #6f6f84;
        }
    }
}

//invoice css//
.invoice-container-v2 {
    position: absolute;
    left: 0;
    top: -2000px;
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
    overflow-y: auto;
    font-family: 'Manrope-Regular';

    .invoice {
        top: 0;
        width: 700px;
        background-color: white;
        padding: 25px 20px 0px 20px;
        display: flex;
        flex-direction: column;

        .grey-text {
            color: $theme-subuser;
        }

        .tr-border {
            border-bottom: 0.5px solid #6f6f84;
        }

        .invoice-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            color: #6f6f84;

            .invoice-logo {
                width: 170px;
                height: 80px;
            }

            .company-name {
                margin: 0;
            }

            .company-address {
                display: flex;
                flex-direction: column;
                text-align: end;

                * {
                    margin: 0;
                }
            }
        }

        .invoice-main-content {
            .company-details {
                border-bottom: 0.5px solid $theme-subuser;
                padding-bottom: 10px;
                margin-bottom: 10px;

                * {
                    margin: 0;
                }

                display: flex;

                .part-title {
                    color: #6f6f84;
                    font-size: 14px;
                }

                .part-1 {
                    width: 35%;
                }

                .part-2 {
                    width: 35%;
                    text-align: end;
                }
            }

            .product-details {
                margin-bottom: 30px;

                span,
                .invoice-table-header {
                    color: $theme-subuser;
                }

                td {
                    padding: 5px 0;
                }
            }

            .total-container {
                table {
                    width: 35%;

                    td {
                        padding: 2px 0;
                    }
                }
            }
        }

        .invoice-table-header {
            color: #6f6f84;
        }

        .additional-info {
            color: #6f6f84;
        }

        .auth {
            color: #6f6f84;
            margin-top: 20px;
        }

        .invoice-footer {
            * {
                margin: 0;
            }

            color: #6f6f84;
        }
    }
}

//email template//
.thanks-email-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
    }

    .gray {
        color: $theme-subuser;
    }

    .radiant {
        color: $theme-peacock;
    }

    .email-background {
        width: 50%;
        background-color: $Back-ground;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 25px auto;

        .logo {
            width: 15%;
            margin: 10px 0;
        }

        .email-content {
            width: 80%;
            background-color: $White-Color;

            .image-container {
                .email-bg-2 {
                    height: 90%;
                    bottom: 0;
                }

                .email-bg {
                    width: 100%;
                }
            }

            .social-media {
                height: 2em;
            }

            .email-btn {
                padding: 10px 20px;
                letter-spacing: 1.5px;
            }

            .email-divider {
                height: 1.5px;
                background-color: $theme-peacock;
                width: 90%;
                margin: 0 auto;
            }
        }
    }
}

//email template//
