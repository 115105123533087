.notification-container {
    height: 90%;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background-color: $theme-light-blue;
        color: $theme-subuser;
        margin-right: 10px;

        .clear-all {
            color: $theme-blue;
            cursor: pointer;
        }
    }
    .content-container {
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        padding-bottom: 15px;
        .content {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            background-color: $White-Color;
            margin-top: 10px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-left: 6px solid $theme-blue;
            min-width: 1120px;
            .date-time {
                color: #626262;
            }
            .user-info {
                display: flex;
                width: 90%;
                .user-name {
                    margin-right: 10px;
                    .not-img {
                        height: 30px;
                        width: 30px;
                        margin-top: 2px;
                    }
                }
                .notification {
                    width: 95%;
                    display: flex;
                    flex-direction: column;
                    .not-title {
                        color: $theme-blue;
                        line-height: 1;
                    }
                    .not-message {
                        color: #626262;
                    }
                }
            }
        }
    }
}
