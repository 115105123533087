.trusted {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 25px;
    background-color: $White-Color;
    @media screen and (max-width: 992px) {
        padding: 50px 15px;
    }
    .title {
        text-align: center;
    }
    .companies {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        border: 4px solid $theme-peacock;
        border-radius: 1em;
        margin-top: 20px;
        img {
            margin: 2.5%;
            width: 10%;
        }
        @media screen and (max-width: 992px) {
            img {
                width: 28%;
            }
        }
    }
}
