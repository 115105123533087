.benchmark {
    padding: 50px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .features-description {
        width: 80%;
        @media screen and (max-width: 992px) {
            text-align: justify;
        }
    }
    .feature-bg {
        margin: 50px 0 0 0;
        width: 60%;
    }
    .feature-bg {
        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }
}
.features {
    padding: 50px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .subtitle,
    .title {
        width: 60%;
        @media screen and (max-width: 992px) {
            width: 80%;
        }
    }
    .subtitle {
        @media screen and (max-width: 992px) {
            text-align: justify;
        }
    }
    .card-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        @media screen and (max-width: 992px) {
            margin-top: 40px;
        }
        .feature-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 18em;
            height: auto;
            padding: 25px;
            margin: 4%;
            border-radius: 0.5em;
            cursor: pointer;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            transition-duration: 0.5s;
            &:hover {
                box-shadow: rgba(17, 178, 184, 0.15) 0 0 50px 50px;
            }
            .card-img {
                height: 3em;
                width: 4em;
            }

            .card-name {
                margin: 20px 0;
            }
            .card-details {
                text-align: justify;
            }
            @media screen and (max-width: 992px) {
                width: 16em;
                margin: 2.5% 0;
            }
        }
    }
}
