.intro-padding {
    padding: 125px 10px 10px 10px;
}
.radiant {
    color: $theme-peacock;
}
.career-intro-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .subtitle {
        margin: 10px 0;
        width: 80%;
    }
    .career-bg {
        width: 100%;
    }
    .img-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding: 50px 0;
        width: 100%;
        .life-at-incruiter {
            width: 26%;
        }
    }
    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .quality-card {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
            border-radius: 0.4em;
            width: 22em;
            margin: 2.5%;
            padding: 30px;
            position: relative;
            .card-name {
                text-align: justify;
                margin-bottom: 10px;
            }
            .card-info {
                text-align: justify;
            }
            .card-no {
                position: absolute;
                top: 0;
                right: 0;
                width: 2.2em;
                border-bottom-left-radius: 50%;
                color: $White-Color;
            }
            .blue {
                background-color: $theme-blue;
            }
            .peacock {
                background-color: $theme-peacock;
            }
        }
    }
}
.we-are-hiring {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    .search-bar {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 20px;
        width: 100%;
        .search {
            border: 1.5px solid $theme-light-gray;
            outline: none;
            border-radius: 0.4em;
            padding: 5px 10px;
            width: 30em;
            color: $theme-gray;
            @media screen and (max-width: 598px) {
                width: 95%;
            }
        }
    }
    .search-btn {
        border-style: none;
        border: 1.5px solid $theme-blue;
        background-color: $White-Color;
        color: $theme-blue;
        padding: 5px 20px;
        border-radius: 0.4em;
        margin-left: 40px;
        @media screen and (max-width: 598px) {
            margin: 20px 0;
        }
    }
    .job-card-container {
        width: 100%;
        .job-card {
            width: 60em;
            margin: 30px auto;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
            border-radius: 0.4em;
            padding: 20px;
            background-color: $White-Color;
            @media screen and (max-width: 992px) {
                width: 90%;
            }
            .main-info-box,
            .sub-info-box {
                display: flex;
            }
            .main-info-box {
                justify-content: space-between;
                align-items: center;
                .dropdown {
                    border-style: none;
                    background-color: transparent;
                    padding: 5px;
                    margin-left: 10px;
                    transition: all 0.4s ease-in-out;
                }
                .down {
                    transform: rotate(90deg);
                }
            }
            .detail-box {
                overflow: hidden;
                transition: height 0.4s ease-in-out;
                .details {
                    margin: 10px 0;
                    text-align: justify;
                }
            }
        }
    }
}

.apply-form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;

    .form {
        position: absolute;
        width: 550px;
        height: auto;
        background-color: $White-Color;
        border-radius: 0.4em;
        padding: 10px 20px;
        @media screen and (max-width: 598px) {
            width: 90%;
        }

        .form-title {
            text-align: center;
            position: relative;
            .close-form {
                position: absolute;
                right: 0;
                top: 0;
                height: 5px;
                width: 5px;
            }
        }
        .form-input-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input-box {
                width: 48%;
                margin: 10px 0;
            }
        }
        .submit-btn {
            padding: 0.6em 1.2em;
        }
    }
}
