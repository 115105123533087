.back-btn-container {
    cursor: pointer;
    img {
        height: 0.8em;
    }
    .back {
        color: $theme-blue;
        margin-left: 5px;
    }
}
