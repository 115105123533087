.permission-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f6f7fb;
}

.permission-left {
    width: 25%;
}

.permission-right {
    width: 75%;
}

.back-container {
    height: 20px;
    .back-arrow {
        height: 0.5em;
    }

    .back {
        color: $theme-blue;
    }
}
.role-input {
    @media screen and (max-width: 992px) {
        width: 50%;
    }
    @media screen and (max-width: 580px) {
        width: 100%;
    }
}

.dashboard-blue {
    background-color: $theme-light-blue;
}

.permission-main-container {
    height: calc(100% - 20px);
    .permission-container {
        .permission-content {
            height: 1em;
            color: $theme-subtitle-color;

            .content-menu {
                left: 0%;
            }

            .content-action {
                left: 25%;
            }

            .content-status {
                left: 50%;
            }
        }

        .permission-card {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
            margin: 0 15px;
            padding: 10px;
            @media screen and (max-width: 992px) {
                width: 100%;
            }
            .switch-container {
                height: 2em;
                width: 38%;
                @media screen and (max-width: 580px) {
                    width: 38%;
                }

                .add-permission {
                    color: $theme-blue;
                }

                .sub-per-switch {
                    left: 50%;
                }

                .on-permission {
                    left: 70%;
                    color: $theme-blue;
                }
            }

            .permission-card-line {
                border-bottom: 1px solid $theme-light-blue;
            }
        }
    }
}

.permission-drop-down {
    width: 85px;
    border: 1.5px solid $theme-light-blue;
    border-radius: 5px;
    height: 30px;
}
.active-dropdown {
    color: $theme-blue;
}
.inactive-dropdown {
    color: $theme-subtitle-color;
}

.action-des-container {
    list-style: none;
    margin: 10px 20px;
    padding: 0;
}
.permission-table-container {
    height: 95%;
    overflow-y: auto;
    .permission-tabs-container {
        display: flex;
        padding: 10px 0 0 10px;
        .permissions-tab {
            flex: 1 1 auto;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            padding: 9px 15px;
            border-radius: 2px 2px 0px 0px;
            border: 1px solid #f0f0f0;
            margin-right: 2px;
        }
        .permission-active-tab {
            background-color: #133f7d;
            color: white;
        }
        .permission-inactive-tab {
            background-color: white;
            color: #000000;
        }
    }
    .permissions-checkbox-names {
        display: flex;
        justify-content: flex-end;
        padding: 12px 28px;
        .permissions-checkbox-name {
            width: 14.75%;
            font-size: 12px;
            text-align: center;
        }
    }
    .permissions-card-container {
        padding: 10px 0 0 10px;
        .permission-card {
            margin-bottom: 20px;
            padding: 12px 28px;
            background-color: white;
            display: flex;
            .permission-card-part1 {
                width: 40%;
                .permission-card-title {
                    font-size: 14px;
                }
                .permission-card-subtitle {
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            .permission-card-part2 {
                width: 60%;
                display: flex;
                justify-content: end;
                .permission-checkbox-container {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .permission-card-checkbox {
                        width: 1em;
                        height: 1em;
                        accent-color: #133f7d;
                    }
                }
            }
        }
    }
}
.set-default {
    color: $theme-blue;
    text-decoration: underline;
    text-decoration-color: $theme-blue;
    margin: 0 20px;
    cursor: pointer;
}
.permission-save-btn {
    margin: 10px auto;
    padding: 8px 24px;
}
.permission-save-btn:disabled {
    opacity: 0.7;
}
.perm-divider {
    border-right: 1.5px solid #e9e9e9;
    height: 95%;
}
.permission_name {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.86px;
    color: rgba(19, 63, 125, 1);
    font-family: 'Manrope-Bold';
    margin-bottom: 0;
}
.gap-10 {
    gap: 10px;
}
.w-8 {
    width: 8%;
}
.dropdown-icon {
    transition: transform 0.3s ease-in-out;
}
.dropdown-icon.open {
    transform: rotate(180deg);
    height: 22px;
}
.permission-card-subresources {
    height: 40px;
    background-color: #f4f4f4 !important;
}
.permission-card-subresources-part2 {
    margin-right: 38px;
}
.permission-card-subuser {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 20px;
}
