.payment-info-wrapper {
    background-color: $Back-ground;
    width: 100%;
    padding: 1.8em 2.2em;
    overflow-y: auto;

    @media screen and (max-width: 580px) {
        padding: 1em;
    }

    .pi-title {
        font-size: 1.6em;
        display: flex;
        align-items: center;
        justify-content: center;
        .pi-back {
            color: $theme-blue;
            .back {
                text-decoration: underline;
                text-decoration-color: $theme-blue;
            }
            .pi-back-arrow {
                height: 0.8em;
                margin-right: 5px;
            }
        }
    }

    .pi-pay-detail {
        color: $theme-placeholder;
    }
    .pi-detail-title {
        color: $theme-blue;
    }

    .pi-content-box {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
            flex-direction: column;
        }
        .pi-content-1 {
            width: 60%;
            @media screen and (max-width: 992px) {
                width: 100%;
            }
            .bill-input {
                width: 45%;
                @media screen and (max-width: 580px) {
                    width: 100%;
                }
            }
        }

        .pi-content-2 {
            width: 38%;
            @media screen and (max-width: 992px) {
                margin: 50px auto;
                width: 60%;
            }
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            .pi-2-main {
                width: 90%;
                @media screen and (max-width: 992px) {
                    width: 100%;
                }
            }

            .pi-tax-box {
                padding: 10px 20px;
                background-color: $White-Color;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .pi-tax-1,
                .pi-tax-2 {
                    display: flex;
                    flex-direction: column;
                }

                .pi-tax-1 {
                    width: 100%;
                    .pi-tax-title {
                        color: $theme-blue;
                        text-overflow: initial;
                    }
                    .pi-tax-total {
                        color: $theme-light-green;
                    }
                    .pi-tax-total,
                    .pi-tax-content {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .pay-now-container {
        background: rgba(217, 217, 217, 0.6);
        border-bottom-left-radius: 0.4em;
        .pay-mode {
            height: 0.8em;
            margin: 0 5px;
        }
        .pay-now {
            padding: 10px 20px;
            border-bottom-right-radius: 0.4em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $theme-blue;
            border-style: none;
            color: $White-Color;
            img {
                height: 1em;
                margin-right: 5px;
            }
        }
    }

    .bg-img {
        top: 0;
        right: 5%;
        width: 22%;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
}
