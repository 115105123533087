.calender-invite {
    height: 500px;
    width: 550px;
    background-color: $White-Color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.4em;
    background-color: #f6f7fb;

    .calender-invite-mail-body {
        background-color: white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .mail-link {
            word-wrap: break-word;
            padding: 10px;
            color: #cfcfcf;
            border: none;
            background-color: #565656;
            resize: none;
        }
        .mail-body {
            background-color: white;
            word-wrap: break-word;
            padding: 10px;
            color: #565656;
            border: none;
            resize: none;
            &:focus {
                outline: none;
            }
        }
    }

    .label {
        color: $theme-placeholder;
    }

    .candidate,
    .interviewer {
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            cursor: pointer;
        }
    }

    .ci-title-box {
        color: $theme-blue;
        width: 100%;
        .ci-close-btn {
            width: 0.3em;
            height: 0.3em;
        }
    }
    .calender-invite-content {
        height: 90%;
        overflow-y: auto;
        width: 100%;
        padding: 0 20px;
        .gray-border {
            border: 1px solid #cccccc;
        }
        .input-field-disabled {
            background-color: #fff !important;
        }
    }
    .ci-input-box {
        .ci-role-input {
            height: 2.5em;
            width: 90%;
            padding-left: 0.5em;
            border-style: none;
            border-bottom: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-datetime-input {
            height: 2.5em;
            width: 90%;
            border-style: none;
            padding-left: 0.5em;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-mail-input {
            height: 15em;
            width: 90%;
            border-style: none;
            padding: 0.5em;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-to-input,
        .ci-guest-input {
            height: 2.5em;
            width: 90%;
            padding-left: 0.5em;
            border-style: none;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }
    }

    .ci-invite-btn {
        padding: 0.6em 1.2em;
    }
    .ReactTags__tagInputField {
        width: 100%;
        border: none;
        padding: 1.2em;
        &:focus {
            outline: none;
        }
    }
}

.custom-container {
    background-color: #f3f3f3;
    padding: 8px 16px 16px 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
}
.main-card-container {
    .card-container {
        flex: 1;
        // height: 100%;
        overflow-x: auto;
        @media screen and (max-width: 992px) {
            padding-right: 0;
            overflow: initial;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $theme-blue;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $theme-blue;
        }

        .interview-card.active {
            background: rgba(19, 63, 125, 0.15);
        }

        .feedback-btn {
            border-style: none;
            background-color: $theme-blue;
            color: $White-Color;
            border-radius: 0.5em;
            width: 80px;
            height: 24px;
            padding: 10px;
            display: flex;
            align-items: center;
            line-height: 0;
            img {
                margin-left: 5px;
                height: 0.8em;
                width: auto;
                font-size: 10px !important;
            }
        }

        .c-invite {
            border-radius: 0.5em;
            z-index: 2000;
        }

        .i-invite {
            margin-right: 10px;
        }

        .replicate-btn {
            border-style: none;
            border: 1.5px solid $theme-light-green;
            border-radius: 0.5em;
            color: $theme-light-green;
            background-color: transparent;
            padding: 0.6em;
            height: 2.4em;

            img {
                height: 1em;
                vertical-align: initial !important;
                font-size: 10px !important;
            }
        }

        .interviewer-card {
            height: auto;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            background-color: $White-Color;
            .gray-text {
                color: $theme-placeholder;
                display: flex;
                align-items: center;
                img {
                    height: 1em;
                }
                .cloud {
                    margin-left: 5px;
                }
                .doc {
                    margin: 0 5px 0 10px;
                }
                .clock {
                    margin-right: 5px;
                }
            }
            .reject-interview {
                color: $theme-red;
                margin-bottom: 5px;
                cursor: pointer;
            }
            .bid-btn {
                border-radius: 0.4em;
                padding: 0.5em 1em;
                background-color: $White-Color;
                img {
                    margin-right: 10px;
                    height: 1em;
                }
            }
            .upload-btn {
                border-radius: 0.4em;
                width: 12em;
                height: 2em;
                border-radius: 0.4em;
                background-color: $White-Color;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row-reverse;
                margin-bottom: 10px;
                img {
                    margin-left: 10px;
                    height: 1em;
                    width: 1em;
                }
            }
            .ss-dropdown {
                border-radius: 0.4em;
                padding: 10px;
                background-color: $White-Color;
                width: 100%;
                z-index: 1;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
                span {
                    padding: 0.5em;
                    color: $theme-subuser;
                    border-radius: 0.4em;
                    cursor: pointer;
                    &:hover {
                        background-color: $theme-light-blue;
                        color: $theme-blue;
                    }
                }
            }
            .bid-blue {
                border: 1.5px solid $theme-blue;
                color: $theme-blue;
            }
            .bid-red {
                border: 1.5px solid $theme-red;
                color: $theme-red;
            }
            .current-bid {
                .peacock {
                    .bid-dot {
                        background-color: $theme-peacock;
                    }
                    .bid-price {
                        color: $theme-peacock;
                    }
                }
                .error {
                    .bid-dot {
                        background-color: $theme-red;
                    }
                    .bid-price {
                        color: $theme-red;
                    }
                }
                .bid-dot {
                    height: 0.8em;
                    width: 0.8em;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                .time-left-bid {
                    margin-left: 5px;
                    color: $theme-gray;
                }
            }
            .interview-btn {
                flex-direction: row-reverse;
                width: 12em;
                height: 2em;
                border-radius: 0.4em;
                margin-right: 15px;
                margin-bottom: 10px;
                img {
                    height: 1em;
                    margin-left: 10px;
                }
            }
            .feedback-report-box {
                .feedback-btn {
                    background-color: $theme-light-blue;
                    height: 2em;
                    width: 2em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-style: none;
                    border-radius: 0.4em;
                    margin: 5px;
                    img {
                        height: 1em;
                    }
                }
            }
        }
        .my-interviews-table-header {
            text-align: center;
            background-color: $theme-light-blue;
            display: flex;
            align-items: center;
            height: 70px;
            min-width: 1140px;
            .double-arrow {
                height: 0.7em;
                margin-left: 10px;
            }
        }
        .my-interviews-table-content {
            min-width: 1140px;
            .my-interviews-table-row {
                padding: 15px 0;
                display: flex;
                align-items: center;
                height: 70px;
                padding: 15px 0;
                background-color: #ffffff;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
            }
        }
    }

    .info-main-container {
        padding: 10px;
        flex: 1;
        max-width: 40%;
        background-color: white;
        @media screen and (max-width: 992px) {
            max-width: 100%;
        }
    }

    .info-container {
        .info-box {
            .label-box {
                .label {
                    color: white;
                    padding: 0.3em 1em;
                    border-bottom-right-radius: 0.3em;
                    border-top-right-radius: 0.3em;
                    margin-right: 20px;
                    background-color: green;
                }
            }

            .feedback-box {
                .info-id {
                    color: $theme-placeholder;
                }
            }

            .name-info {
                color: $theme-placeholder;
            }
        }

        .divider-box {
            border-bottom: 1px solid $theme-placeholder;
        }
    }
    // bulk upload css //
    .bulk-upload-title {
        height: 18px;
    }
    .bulk-upload-table {
        height: 300px;
        .w-8 {
            width: 8%;
        }
        .w-16 {
            width: 16.8%;
        }
        .bu-header {
            background-color: $theme-light-blue;
            padding: 15px 10px;
            margin: 5px 10px 0 0;
            .bu-header-content {
                color: $theme-arrow;
                img {
                    height: 0.6em;
                    margin-left: 5px;
                }
            }
        }
        .bu-content-container {
            height: calc(100% - 60px);
            padding-right: 10px;
            overflow-y: auto;
            .bu-content {
                margin-top: 10px;
                padding: 15px 10px;
                background-color: $White-Color;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            }
        }
    }
    // bulk upload css //
}

/* feedback report css */
.download-pdf {
    width: 1em;
}

.candi-feedback-box {
    height: 100px;
}

.candi-performance {
    height: 90%;
    width: 100px;
    svg {
        height: 100%;
    }
}

.man-skill-box {
    height: auto;
    border: 1.5px solid $theme-light-blue;
}

.performance-review {
    width: 70%;
}

.skill-performance {
    height: 70px;
    width: 70px;
    margin-top: 4px;
    svg {
        height: 100%;
    }
}

.dot {
    height: 0.3em;
    width: 0.3em;
    border-radius: 50%;
}

.dot-review-red {
    color: $theme-red;
}

.dot-review-green {
    color: $theme-green !important;
}

.dot-review-yellow {
    color: $theme-yellow !important;
}

.skill-review-box,
.final-remarks {
    height: auto;
    width: 100%;
    padding: 10px;
    border: 1px solid $theme-placeholder;
    color: $theme-placeholder;
    text-align: justify;
}
// .sef-container {
//   height: 2em;
//   @media screen and (max-width: 992px) {
//     width: 100%;
//   }
// }

.info-btn-close {
    width: 5px;
    height: 5px;
    top: 10px;
    right: 10px;
}

.red {
    background-color: $theme-red !important;
}

.green {
    background-color: $theme-green !important;
}

.yellow {
    background-color: $theme-yellow !important;
}

.carrot {
    background-color: $theme-carrot !important;
}

.grey {
    background-color: $theme-subuser !important;
}

.meeting-link-btn {
    border-style: none;
    font-size: 10px;
    margin-right: 10px;
    padding: 1px;
    color: $theme-blue;
    height: 2.6em;
    width: 2.6em;
    .copy-link {
        width: 10px;
        height: auto;
    }
}

.meeting-link-btn-active {
    background-color: $theme-light-blue;
    cursor: pointer;
}

.meeting-link-btn-inactive {
    background-color: #e7e9f8;
}

.info-main-container {
    position: fixed;
    overflow-y: auto;
    background-color: #fff;
    height: calc(100% - 60px);
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
    right: 0;
    padding: 10px;
    transition: all 0.5s ease-out;
    top: 60px;
    @media screen and (min-width: 993px) {
        width: 50%;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.invite-div-join-meet {
    border: none;
    margin-right: 4px;
    height: 2.4em;
    width: 2.4em;
    cursor: pointer;
    .join-meet-img {
        height: 40%;
        width: auto;
    }
}

.invite-div-join-meet-active {
    background-color: $theme-light-blue;
    cursor: pointer;
}

.invite-div-join-meet-inactive {
    background-color: #e7e9f8;
    cursor: not-allowed;
}

.interview-card a:hover {
    color: #ffffff;
}
.calender-invite-content .w-47 {
    min-width: 90px;
}

.feedback-download-btn {
    border: none;
    background-color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $White-Color;
    .feedback-download-arrow {
        height: 13px;
        width: auto;
    }
}

.very-poor-bg {
    background-color: #ff0000;
}
.poor-bg {
    background-color: #ff9f43;
}
.average-bg {
    background-color: #e8aa4e;
}
.good-bg {
    background-color: #8db627;
}
.very-good-bg {
    background-color: #30a400;
}
.excellent-bg {
    background-color: #008c15;
}

.very-poor-color {
    color: #ff0000;
}
.poor-color {
    color: #ff9f43;
}
.average-color {
    color: #e8aa4e;
}
.good-color {
    color: #8db627;
}
.very-good-color {
    color: #30a400;
}
.excellent-color {
    color: #008c15;
}

.final-rating-text {
    text-align: center;
    width: 100px;
}

.status-pending {
    background-color: #9b9b9b !important;
}
.status-cancelled {
    background-color: #626262 !important;
}
.status-rejected {
    background-color: #9b9b9b !important;
}
.status-noshow {
    background-color: #626262 !important;
}
.status-hold {
    background-color: #12b6bc !important;
}
.status-scheduled {
    background-color: #126abc !important;
}
.status-scheduled-tab2 {
    background-color: #ff822e !important;
}

.status-shortlisted {
    background-color: #12b6bc !important;
}

.shortlisted-color {
    color: #18ac00;
    border: 0.5px solid #18ac00;
    // background-color: #ebf5eb;
    // .dot-color {
    //   background-color: #18ac00;
    // }
}
.scheduled-color {
    color: #ff822e;
    border: 0.5px solid #ff822e;
    // background-color: #fbf3ef;
    // .dot-color {
    //   background-color: #ff822e;
    // }
}
.hold-color {
    color: #e8aa4e;
    border: 0.5px solid #e8aa4e;
    // background-color: #faf5f1;
    // .dot-color {
    //   background-color: #e8aa4e;
    // }
}
.no-show-color {
    color: #626262;
    border: 0.5px solid #626262;

    // background-color: #f0f0f2;
    // .dot-color {
    //   background-color: #626262;
    // }
}
.rejected-color {
    color: #8b0000;
    border: 0.5px solid #8b0000;
    // background-color: rgba(139, 0, 0, 0.07);
    // .dot-color {
    //   background-color: #8b0000;
    // }
}
.cancelled-color {
    color: #f2464b;
    border: 0.5px solid #f2464b;
    // background-color: rgba(254, 242, 242, 1);
    // .dot-color {
    //   background-color: #f2464b;
    // }
}
.pending-color {
    color: #626262;
    border: 0.5px solid #626262;
    // background-color: #f0f0f2;
    // .dot-color {
    //   background-color: #626262;
    // }
}
.completed-color {
    color: #18ac00;
    border: 0.5px solid #18ac00;
    // background-color: #ebf5eb;
    // .dot-color {
    //   background-color: #18ac00;
    // }
}

.active-status-color {
    color: #18ac00;
    border: 0.5px solid #18ac00;
}

.inactive-status-color {
    color: #f2464b;
    border: 0.5px solid #f2464b;
}

.interview-status-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    .status-dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
.work-experiance-info {
    color: #83879e;
    font-weight: 500;
    margin-top: 10px;
}
