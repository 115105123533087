.range-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media screen and (max-width: 992px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 1em;
    }
}

.range-dropdown {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .no-of-rows {
        width: auto;
        background-color: $White-Color;
        margin: 0 10px;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        padding: 5px;
        height: 35px;
    }
}
