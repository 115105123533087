.pagination-container {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding-left: 0;

    .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin: auto 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        letter-spacing: 0.01071em;
        border-radius: 16px;
        line-height: 1.43;
        font-size: 13px;
        min-width: 32px;
        background-color: #e9e9e9;
        color: #6f6f84;

        .pagination-arrow {
            height: 10px;
            width: auto;
        }

        &.dots:hover {
            background-color: #c9c9c9;
            cursor: default;
        }
        &:hover {
            background-color: #c9c9c9;
            cursor: pointer;
        }

        &.selected {
            color: $White-Color;
            background-color: $theme-blue;
        }

        &.disabled {
            pointer-events: none;
            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}
