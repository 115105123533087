.search-img {
    height: 15px;
}

.search-input-type2 {
    padding: 5px;
    height: 35px;
    border-style: none;
    width: 95%;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #c0c0c0;
    }
}

.search-input-type1 {
    height: 3em;
    border-style: none;
    width: 22em;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #c0c0c0;
    }
}

.search-bar-container-type2 {
    width: calc(100% - 450px);
}

.search-bar-container-type1 {
    @media screen and (max-width: 992px) {
        width: 100%;
    }
}
