// dashoatd
.dashContainer {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: #f6f7fb;
    border-top: 2px solid rgba(0, 0, 0, 0.161);
    overflow-y: auto;
    padding: 1em;
    position: relative;
    .select__indicator-separator {
        display: none;
    }

    // justify-content: center;
    // align-items: center;
    @media (max-width: 992px) {
        flex-direction: column;
        height: min-content;
        align-items: center;
    }
}

.dashTitle {
    font-size: 18px;
    font-weight: 600;
    color: #1c4784;
}

.dashCenterDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullCenterDiv {
    width: 100%;
    height: 100%;
}

.dashLeftContainer {
    display: block;
    width: 100%;
    padding-right: 1em;
    overflow-y: auto;
    flex: 1;
    min-width: 742px;
    .dp-title {
        font-size: 1.5em;
    }
    @media screen and (max-width: 992px) {
        padding: 0;
    }
}

.dashRightContainer {
    width: 30%;
    overflow: visible;
    position: relative;
    flex: 0.35;
    background-color: #fbfbfd;
    display: flex;
    flex-direction: column;
    gap: 1.3em;
    padding: 1.5em 1em;
    padding-bottom: 2em;
    @media (max-width: 992px) {
        width: 100% !important;
    }
    .sidebarCollapse {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #12b6bc;
        position: absolute;
        top: 4em;
        left: -16px;
        z-index: 1;
        padding: 2px;
        cursor: pointer;
        @media (max-width: 992px) {
            display: none !important;
        }
    }
}
.dashRightContainerHide {
    flex: 0 !important;
    .sidebarCollapse {
        right: 2.5%;
    }
    .d-none {
        display: none;
    }
}

.leftContainerBody {
    width: 100%;
    margin-top: 20px;

    @media (max-width: 1366px) {
        margin-top: 10px;
    }
}

.calendarTitleArea {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.calendarTitle {
    font-size: 15px;
    font-weight: 700;
    color: #595d7a;

    @media (max-width: 1366px) {
        font-size: 11px;
    }
}

.seeAll {
    font-size: 11px;
    font-weight: 600;
    color: #83879e;
    cursor: pointer;

    @media (max-width: 1366px) {
        font-size: 8px;
    }
}

.calendarArea {
    width: 100%;
    height: 40%;
}

.eventsArea {
    width: 100%;
    height: 52%;

    @media (max-width: 1366px) {
        height: 51%;
    }
}

.eventDate {
    font-size: 14px;
    font-weight: 600;
    color: #0d0c0d;

    @media (max-width: 1366px) {
        font-size: 11px;
    }
}

.events {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #e7e7e7;
    }

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #e2e7f3;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
    }

    @media (max-width: 1366px) {
        ::-webkit-scrollbar {
            width: 4px;
        }

        @media (max-width: 992px) {
            overflow-y: initial;
        }
    }
}

.flexDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.eventCardWrapper {
    height: 65px;
    width: 84%;
    border-radius: 20px;
    border: 1px solid #8780ab;
    margin-bottom: 10px;
    padding: 15px;

    @media (max-width: 1366px) {
        height: 45px;
        border-radius: 10px;
        margin-bottom: 8px;
        padding: 10px;
    }
}

.eventCardRounded {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #8780ab;

    @media (max-width: 1366px) {
        height: 6px;
        width: 6px;
    }
}

.eventCardFlex {
    display: flex;
    align-items: center;
}

.eventCardTime {
    font-size: 11px;
    font-weight: 500;
    margin-left: 5px;

    @media (max-width: 1366px) {
        font-size: 9px;
        margin-left: 4px;
    }
}

.eventCardThreeDot {
    width: 15px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1366px) {
        width: 10px;
        height: 7px;
    }
}

.eventCardIcon {
    width: 12px;
    height: 3px;

    @media (max-width: 1366px) {
        width: 9px;
        height: 2px;
    }
}

.eventCardFirstLine {
    justify-content: space-between;
    margin-bottom: 5px;

    @media (max-width: 1366px) {
        margin-bottom: 2px;
    }
}

.eventCardSecondLine {
    font-size: 14px;
    font-weight: 600;
    color: #0d0c0d;
    margin-bottom: 5px;

    @media (max-width: 1366px) {
        font-size: 11px;
        margin-bottom: 2px;
    }
}

.eventCardThirdLine {
    font-size: 14px;
    font-weight: 700;
    color: #595d7a;

    @media (max-width: 1366px) {
        font-size: 9px;
    }
}

.calendarContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashCalendar {
    @media screen and (max-width: 992px) {
        width: 100% !important;
    }
}

.eventColorCircle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $theme-teal;
}

.eventsCard {
    border: 1px solid $theme-blue;
}

.product-stats-container {
    background-color: $White-Color;
}

.dp-progesscard-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        align-items: center;
    }
}

.progress-card {
    background-color: $White-Color;
    padding: 5px;
    width: 210px;
    margin: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    @media screen and (max-width: 1460px) {
        width: 170px;
        .progress-card-title {
            font-size: 11px;
        }
    }
}

.progress-card-big {
    background-color: $White-Color;
    padding: 5px;
    width: 210px;
    margin: 5px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    @media screen and (max-width: 1460px) {
        width: 170px;
        .progress-card-title {
            font-size: 11px;
        }
    }
}

.pc-img {
    height: 1.8em;
    margin-right: 15px;
    background: #f6f7fb;
    padding: 5px 7px;
    border-radius: 3px;
}

.pc-progress {
    margin-top: 15px;
    height: 0.7em;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: $theme-light-gray;
}

.pc-progress-bar {
    height: 100%;
    margin: 0;
}

.type-1 {
    background: linear-gradient(270deg, #11b2b8 1.47%, #133f7d 98.82%);
}

// .type-2 {
//   background-color: $theme-peacock;
// }

// .type-3 {
//   background-color: $theme-lavender;
// }

.dp-stat-title {
    @media screen and (max-width: 992px) {
        padding-left: 20px;
    }
}

.dp-stat-res {
    @media screen and (max-width: 1300px) {
        font-size: 12px !important;
        margin-top: 5px;
    }
    @media screen and (max-width: 1100px) {
        font-size: 10px !important;
        margin-top: 5px;
    }
}

.progress-card-text {
    text-align: center;
    text-transform: capitalize;
}
.interviewer-graph-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 50px;
    .interviewer-barchart,
    .interviewer-piechart {
        width: 49%;
        padding: 20px;
        background-color: $White-Color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0.4em;
        height: 350px;
        @media screen and (max-width: 992px) {
            width: 90%;
            margin-top: 10%;
        }
    }
}

.schedule-popup-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
    .schedule-popup {
        width: 750px;
        height: 500px;
        background-color: $theme-bg;
        padding: 18px;

        .calendar-slots-container {
            display: flex;
            height: 80%;
            .calendar-part {
                width: 60%;
                .calendar-btn-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .cal-btn {
                        height: 2.5em;
                        width: 9em;
                        border-radius: 0.4em;
                        margin: 10px 5px;
                    }
                    .outline {
                        border: 1.5px solid $theme-blue;
                        background-color: $White-Color;
                    }
                    .light-blue {
                        border: none;
                        background-color: $theme-light-blue;
                    }
                }
            }
            .schedule-part {
                width: 40%;
                display: flex;
                flex-direction: column;
                height: calc(100% - 18px);
                border-left: 1.5px solid $theme-gray;
                .time-slot-container {
                    height: 95%;
                    overflow-y: auto;
                    padding: 0 10px;
                    .time-slot {
                        background-color: $White-Color;
                        margin-top: 5px;
                        padding: 5px 0;
                        .time-drop {
                            background-color: $theme-light-blue;
                            height: 25px;
                        }
                    }
                    .add-slot-btn {
                        padding: 0.5em 1em;
                        border-radius: 0.4em;
                        margin: 10px 0;
                        img {
                            margin-right: 5px;
                        }
                    }
                    .red-trash {
                        height: 18px;
                        width: auto;
                    }
                }
            }
        }

        .schedule-btn-container {
            display: flex;
            justify-content: center;
            padding: 10px 0px;
            .common-btn,
            .outline-btn {
                padding: 0.5em 1em;
                border-radius: 0.4em;
            }
            .common-btn {
                margin-right: 10px;
            }
            .outline-btn {
                background-color: $White-Color;
            }
        }
    }
}
