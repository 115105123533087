.excel-btn,
.excel-btn-disabled {
    border-style: none;
    padding: 5px 10px;
    height: 35px;
    box-sizing: border-box;
    img {
        height: 12px;
        width: auto;
        margin-left: 10px;
    }
}

.excel-btn {
    background-color: $theme-blue;
    color: $White-Color;
}

.excel-btn-disabled {
    cursor: not-allowed;
    background-color: #626262;
    color: #e7e7e7;
}
