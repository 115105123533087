.how-it-works {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 20px;
    position: relative;
    background-color: #f5f9ff;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
    .card-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .part-1,
    .part-2 {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 4%;
    }

    .step-card {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 0.5em;
        position: relative;
        cursor: pointer;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition-duration: 0.5s;
        padding: 40px;
        width: 20em;
        &:hover {
            box-shadow: rgba(17, 178, 184, 0.15) 0 0 100px 100px;
        }
        @media screen and (max-width: 992px) {
            margin: 8% 2.5%;
        }
        @media screen and (max-width: 600px) {
            margin: 15% 0 0 0;
        }
        @media screen and (max-width: 450px) {
            width: 16em;
            margin-top: 25%;
        }

        .card-number {
            position: absolute;
            bottom: 82%;
            right: 82%;
            height: 30%;
        }
        .title {
            margin: 25px 0 10px 0;
            width: 100%;
            position: relative;

            .small-quote {
                position: absolute;
                right: 100%;
                bottom: 100%;
            }
        }
    }

    .part-1 {
        .title-box {
            position: relative;
            .double-quote {
                position: absolute;
                right: 95%;
                bottom: 15%;
                width: 6em;
                z-index: -1;
            }
        }
        .step-card {
            margin: 150px 0;
        }
    }

    .part-2 {
        .step-card {
            margin: 100px 0;
        }
    }

    .pointer1,
    .pointer2,
    .pointer3,
    .pointer4 {
        position: absolute;
        width: 10%;
    }

    .pointer1 {
        top: 14%;
        left: 45%;
    }
    .pointer2 {
        top: 40%;
        left: 45%;
    }
    .pointer3 {
        top: 61%;
        left: 48%;
    }
    .pointer4 {
        top: 78%;
        left: 44%;
    }
    .download-btn-container {
        margin-top: 20px;
        .download-btn {
            margin: auto;
            padding: 5px 10px;
            border-radius: 0.4em;
        }
    }
}

//steps//
.steps {
    background-color: $theme-light-peacock;
    @media screen and (max-width: 992px) {
        padding: 10% 5%;
    }
    .part-1,
    .part-2 {
        width: auto;
        margin: 0;
        .part-1 {
            align-items: flex-end;
        }
        .part-2 {
            align-items: flex-start;
        }
        @media screen and (max-width: 992px) {
            width: 100%;
            align-items: center;
        }
    }
    .step-card {
        background-color: $White-Color;
        width: 30em;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        @media screen and (max-width: 992px) {
            width: 100%;
        }

        .step-count {
            color: $theme-peacock;
            width: 100%;
        }
        .title {
            margin: 0;
        }
    }
    .part-1 {
        .title-box {
            z-index: 1;
            @media screen and (max-width: 992px) {
                text-align: center;
            }
            .double-quote {
                position: absolute;
                right: 95%;
                bottom: 60%;
                width: 6em;
                z-index: -2;
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }
        }
    }
    .part-1,
    .part-2 {
        .step-card {
            margin: 20px;
        }
    }
}
