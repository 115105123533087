.cpp-divider {
    border-bottom: 1.5px solid #e9e9e9;
}
.w-100 {
    width: 100%;
}
.bu-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    overflow: auto;
    .bu-popup-box {
        background-color: white;
        border-radius: 1em;
        height: auto;
        padding: 1em;
        width: 70%;
    }
    .accordion-container {
        width: 100%;
        .accordion {
            margin: 20px auto 0 auto;
            border-radius: 0.4em;
            background-color: #f6f7fb;
            padding: 16px;
            color: black;
            @media screen and (max-width: 992px) {
                width: 90%;
            }
            .main-info-box,
            .sub-info-box {
                display: flex;
            }
            .down {
                transform: rotate(180deg) !important;
            }
            .main-info-box {
                justify-content: space-between;
                align-items: center;
                .dropdown {
                    border-style: none;
                    background-color: transparent;
                    padding: 5px;
                    margin-left: 10px;
                    color: white;
                    transition: all 0.4s ease-in-out;
                }
                .down {
                    transform: rotate(90deg);
                }
            }
            .detail-box {
                height: 0;
                display: flex;
                overflow: hidden;
                flex-direction: column;
                .details {
                    margin: 0px 0;
                    padding: 10px;
                }
                .bulk-upload-row-wrapper {
                    height: 150px;
                    overflow-y: scroll;
                    padding: 10px;
                }
                .bu-row-container {
                    display: flex;
                    background-color: #fff;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
                    color: #626262;
                    text-align: center;
                    margin-bottom: 10px;
                    padding: 10px;
                }
                .resume-highlight {
                    color: #12b6bc;
                }
            }
            .full-height {
                background-color: #f6f7fb;
                height: 140px;
            }
        }
        .header {
            background-color: #d9d9d9;
            color: $theme-subuser;
            .header-content {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5em 0;
            }
        }
    }
    .cancel-btn {
        background-color: #e96f68;
    }
    .conti-btn {
        background-color: #133f7d;
    }
    .skip-conti-btn {
        background-color: #12b6bc;
    }
    .accordion-success {
        border-top: 5px solid #3a992b;
    }
    .accordion-error {
        border-top: 5px solid #f2464b;
    }
    .accordion-duplicate {
        border-top: 5px solid #f49454;
    }
    .button-wrapper {
        color: white;
    }
    .buttons {
        width: 12em;
    }
}

.cpp-wrapper {
    background-color: $Back-ground;
    position: relative;
    width: 100%;
    padding: 20px;
    @media screen and (max-width: 992px) {
        padding: 1em 1em;
    }
    @media screen and (min-width: 1900px) {
        padding: 3em 3.5em;
    }
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $theme-blue;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: $theme-blue;
    }

    .cpp-title {
        color: $theme-blue;
        .cpp-title-heading {
            display: inline-block;
            border-bottom: 3px solid #133f7d;
            padding: 11px 6px;
            margin: 0;
        }
        .interview-tabs-box {
            .interview-tab {
                padding: 8px 15px;
                background-color: #e9e9e9;
                border-left: 1px solid #f0f0f0;
                border-top: 1px solid #f1f1f1;
                border-right: 1px solid #f5f5f5;
                margin: 0 0.5px;
                border-top-left-radius: 0.4em;
                border-top-right-radius: 0.4em;
                cursor: pointer;
                color: $theme-subuser;
            }
            .selected {
                background-color: $theme-blue;
                color: $White-Color;
            }
        }
    }

    .input-wrapper {
        width: 46%;

        .input[type='file'] {
            display: none;
        }

        ::-webkit-file-upload-button {
            visibility: hidden;
        }

        input::placeholder {
            color: $theme-placeholder;
        }

        .input {
            height: 4em;
            border-style: none;
            background-color: $White-Color;
        }
    }

    .cpp-bg {
        @media screen and (max-width: 1000px) {
            display: none;
        }
        position: absolute;
        width: 32%;
        right: 2.5%;
        top: 30%;
    }

    .questions-wrapper {
        @media screen and (max-width: 900px) {
            width: 100%;
            position: relative;
            z-index: initial;
            top: 0;
            right: 0;
        }
        z-index: 3;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 39%;
        height: calc(100% - 60px);
        background-color: #f0f2f9;
        .screen-qs-wrapper {
            padding: 30px 30px 10px;
            height: calc(100% - 82px);
        }
        .screen-qs-container {
            height: calc(100% - 50px);
            overflow-y: auto;
        }
        .screen-qs-container2 {
            height: calc(100% - 80px);
            // background: #e6e9f8;
            overflow-y: auto;
        }

        .btn-close {
            width: 5px;
            height: 5px;
            top: 2.5%;
            right: 1.5%;
        }

        .custom-qs {
            padding: 1.2em;
            outline: none;
            width: 95%;
        }

        .custom-qs-box {
            background-color: $White-Color;
            border-radius: 0.3em;
            overflow: hidden;
        }
    }
    .spacer-div {
        height: 13px;
        width: 13px;
    }
    .cpp-btn {
        padding: 0.6em 2em;
        border-radius: 0.5em;
        // height:47.5px;
    }
    a {
        text-decoration: none;
    }

    .confirm-interview-popup {
        left: 0;
        top: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        .ci-popup {
            background-color: $theme-bg;
            padding: 40px;
            border-radius: 0.5em;
            display: flex;
            flex-direction: column;
            align-items: center;
            .ci-btn-container {
                margin-top: 25px;
                .ci-btn {
                    width: 6em;
                    height: 3em;
                    border-style: none;
                    margin: 0 10px;
                    color: $White-Color;
                }
                .yes {
                    background-color: $theme-light-green;
                }
                .no {
                    background-color: $theme-subuser;
                }
            }
        }
        .bidding-popup {
            background-color: $theme-bg;
            padding: 20px 10px;
            border-radius: 0.5em;
            width: 370px;
            .time-left {
                .timer {
                    height: 1.2em;
                }
                .left-bid {
                    color: $theme-subuser;
                }
            }
            .current-bid {
                background-color: $White-Color;
                margin-top: 30px;
                text-align: center;
                padding: 10px;
                .bid-by {
                    color: $theme-subuser;
                }
            }
            .bid-input-box {
                margin-top: 25px;
                background-color: $White-Color;
                padding: 10px 20px;
                .bid-label {
                    color: $theme-subuser;
                }
                .bid-input {
                    outline: none;
                    padding: 0 10px;
                    border: none;
                    background-color: $White-Color;
                }
            }
            .bid-input-placed {
                color: $theme-light-green;
                padding: 10px 20px;
                background-color: $White-Color;
                margin-top: 10px;
            }
            .bid-now-btn {
                padding: 0.5em 0;
                border-radius: 0.3em;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .panels-table {
        overflow-x: auto;
        width: 100%;
        padding: 1px;
        .w-5 {
            width: 5%;
        }
        .w-10 {
            width: 10%;
        }
        .w-15 {
            width: 15%;
        }
        .w-20 {
            width: 20%;
        }
        .w-30 {
            width: 30%;
        }
        .panels-header {
            min-width: 1140px;
            background-color: $theme-light-blue;
            color: $theme-subuser;
            display: flex;
            .header-content {
                padding: 15px;
                display: flex;
                align-items: center;
                text-align: center;
                .double-arrow {
                    height: 0.7em;
                    margin-left: 10px;
                }
            }
        }
        .panels-content {
            min-width: 1140px;
            .panel {
                display: flex;
                align-items: center;
                background-color: $White-Color;
                padding: 15px 0;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                span {
                    padding: 10px;
                }
            }
        }
    }
    .z-max {
        z-index: 1000;
    }
    .addPanelist-sideForm {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 50%;
        height: calc(100% - 60px);
        background-color: #ebedf9;
        box-shadow: -2px 0px 12px -4px rgba(105, 105, 105, 1);
        padding: 20px 10px 20px 20px;
        overflow-y: scroll;
        .addPanelist-sideForm-scroll {
            padding-right: 10px;
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: space-between !important;
        }
        .addPanelistInput {
            width: 48%;
        }
        .btn-close {
            width: 5px;
            height: 5px;
        }
    }
}
.no-data-image {
    height: 200px;
    width: auto;
    display: block;
    margin: 0 auto;
}

.add-qs,
.add-custom-qs {
    cursor: pointer;
}

.choose-file {
    height: 4em;
    background-color: $White-Color;
    border: 1.5px dashed #dee2e6;
}

.remark {
    height: 6em;
    border-style: none;
    background-color: $White-Color;
}

.cpp-data {
    @media screen and (min-width: 1000px) {
        width: 64%;
    }
}

.single-candidate-subtitle {
    border-bottom: 1.5px solid #abadb7;
    width: 200px;
    margin-bottom: 10px;
}

.cpp-input-container {
    width: 100%;
    @media screen and (min-width: 1000px) {
        width: 48%;
    }
}

.cpp-input-container-h2 {
    width: 48%;
    @media screen and (min-width: 1000px) {
        width: 22%;
    }
}
.cpp-input-container-h3 {
    width: 48%;
    @media screen and (min-width: 1000px) {
        width: 20%;
    }
}
.cpp-input-container-h4 {
    line-height: 96px;
    height: 96px;
    text-align: center;
}

.incVid-video-platform-popup {
    background-color: $theme-bg;
    padding: 15px;
    position: relative;
    width: 520px;
    .btn-close {
        height: 5px;
        width: 5px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .incVid-platform-popup {
        padding: 10px 5px;
        gap: 8px;
        .incVid-popup-btn {
            width: 100%;
            height: 100px;
            background-color: #172b4d;
            color: $White-Color;
            border: none;
            border-radius: 5px;
            &:hover {
                transform: translateY(-2px);
                box-shadow:
                    0 7px 14px rgba(50, 50, 93, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.08);
                background-color: #172b4d;
                color: $White-Color;
            }
        }
        .incVid-popup-btn-disabled {
            width: 100%;
            height: 100px;
            background-color: #e7e7e7;
            color: #626262;
            border: none;
            border-radius: 5px;
            &:hover {
                background-color: #e7e7e7 !important;
                color: #626262 !important;
                cursor: default;
            }
        }
    }
}

.candidate-popup {
    background-color: $theme-bg;
    padding: 15px;
    position: relative;
    width: 400px;
    .btn-close {
        height: 5px;
        width: 5px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .candidate-popup-btn-container {
        margin-top: 50px;
        .candidate-popup-btn {
            background-color: $theme-blue;
            color: $White-Color;
            border: none;
            padding: 8px 20px;
            border-radius: 5px;
        }
    }
}

//bulkupload popup//
.bulk-upload-popup {
    width: 300px;
    height: auto;
    background-color: $Back-ground;

    .btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 5px;
        width: 5px;
    }
}
//bulkupload popup//
.sq-heading {
    color: #0d0df5;
}
.sq-add {
    color: #0c0c0d;
}
.qs-add-div {
    display: flex;
    align-items: center;
    background-color: #133f7d;
    color: white;
    margin: 0px 10px;
    padding: 0px 16px;
    border-radius: 3px;
}
.qs-question-input {
    width: 100%;
    border: none;
    padding: 1em;
    font-family: 'Manrope-Regular';
    outline: none !important;
    background-color: #ffffff;
}
.custom-qs-container {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
}
.qs-remove-icon {
    height: 13px;
    width: 13px;
}
.sq-generate-btn {
    color: white;
    background-color: #133f7d;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
}

.sq-generate-btn1 {
    color: white;
    background-color: #9b9b9b;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
}
