.c-label {
    font-family: 'Manrope-SemiBold';
    color: $theme-black !important;
}

.c-label-login {
    padding-left: 30px;
}

.c-input-container {
    border: 1px solid $theme-gray;
    overflow: hidden;
}

.c-input {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 36px;
    border: none;
    padding: 1.2em;
    border-radius: 0.5em;
    font-family: 'Manrope-Regular';
    box-shadow: none !important;
    outline: none !important;
    background-color: $White-Color;

    &::placeholder {
        color: $theme-placeholder;
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
}

.c-input-login {
    padding: 0em 1em;
    // padding: 0.3em 1em;
    border-radius: 40px;
    @media screen and (max-width: 992px) {
        padding: 0.3em 1em;
    }
}

.gray-input-border {
    border: 1.4px solid #b2b2b2;
}

.error {
    border: none !important;
}

input[type='checkbox']:checked {
    background-color: $theme-blue !important;
    border-color: $theme-blue !important;
}

input[type='file'] {
    &::-webkit-file-upload-button {
        display: none;
    }
}

.common-input {
    width: 100%;
    border: none;
    padding: 1.2em;
    font-family: 'Manrope-Regular';
    outline: none !important;
    background-color: $White-Color;
    &::placeholder {
        color: $theme-placeholder;
    }
    &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
}
.input-calender-img {
    z-index: 1;
    display: block;
    position: absolute;
    height: 20px;
    right: 10%;
    top: calc(50% - 10px);
}

.remove-field {
    height: 12px;
    width: auto;
}
.custom-field-delete-btn {
    background-color: #ff0000;
    border-radius: 5px;
    height: 25px;
    margin-top: 50px;
}

.add-custom-field-box {
    height: 46px;
}

//guests//
.rti--container {
    border: none !important;
    font-size: 12px;
    padding: 1.2em !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
    .rti--input {
        width: 100% !important;
        color: $theme-placeholder !important;
        &::placeholder {
            color: $theme-placeholder !important;
        }
    }
}
//guest//

// .hide-options {
//   .select__menu {
//     display: none;
//   }
// }

//country code input css
.flagDropdown {
    padding: 6px 0 !important;
    background-color: #ffffff !important;
    .flag-dropdown {
        border: none !important;
        background-color: #ffffff !important;
        border-radius: var(--bs-border-radius) !important;
    }
    .flag-dropdown.open {
        height: 100% !important;
        border-radius: none !important;
        border-radius: var(--bs-border-radius) !important;
    }
    .form-control {
        border: none !important;
        &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}
//country code input css

//new search select css//
.new-search-select-container {
    position: relative;
    .search-input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        .search-input {
            width: 100%;
            border: none;
            outline: none;
        }
        .search-drop-divider {
            height: 100%;
            background-color: #cccccc;
            width: 1.2px;
            margin: 0 8px 0 0px;
            height: 31px;
        }
        .search-drop-container {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .search-drop-arrow {
                width: 11.6px;
                height: 6.5px;
            }
        }
    }
    .search-container-part-2 {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        background-color: #ffffff;
        z-index: 1000;
        .search-container-option {
            padding: 10px;
            &:hover {
                background-color: #deebff;
            }
        }
        .active-option {
            background-color: #deebff;
        }
        .search-container-create-option {
            padding: 10px;
            background-color: #deebff;
        }
        .search-no-option-container {
            text-align: center;
            color: #757575;
            padding: 12px 10px;
            border-radius: 4px;
        }
    }
}
//new search select css//
