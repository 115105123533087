.our-products-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .title {
        margin-top: 30px;
    }
    .subtitle {
        width: 80%;
        @media screen and (max-width: 992px) {
            text-align: justify;
        }
    }
    .op-card-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 50px 0;
        .product-card {
            margin: 1%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: justify;
            width: 18em;
            padding: 2em;
            border-radius: 0.5em;
            cursor: pointer;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            transition-duration: 0.5s;
            &:hover {
                box-shadow: rgba(17, 178, 184, 0.15) 0 0 50px 50px;
            }
            .card-logo {
                height: 4em;
                margin-bottom: 1em;
            }
            .blue-btn {
                margin-top: 2em;
                padding: 8px 12px;
                line-height: 1;
                border-style: none;
                background-color: $theme-blue;
                border-radius: 0.5em;
                color: $White-Color;
                img {
                    height: 1em;
                    margin-right: 10px;
                }
            }
            @media screen and (max-width: 600px) {
                width: 16em;
            }
        }
    }
}
