.subuser-bg {
    width: 42%;
    right: 5%;
    top: 26%;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.subuser-data {
    @media screen and (min-width: 1000px) {
        width: 40%;
    }
    button {
        @media screen and (max-width: 992px) {
            margin: 0 auto;
        }
    }
}
