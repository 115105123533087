.why-choose-us {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 70px 10px;
    position: relative;

    .wcu-bg {
        width: 85%;
        margin: 200px 0;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    .wcu-card {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 10em;
        @media screen and (max-width: 992px) {
            position: initial;
            .wcu-card-title-box {
                display: flex;
                align-items: center;
                .card-title {
                    margin: 10px 0 10px 10px;
                }
            }
        }
        .card-num {
            color: $theme-peacock;
            line-height: 1;
        }
        .card-title {
            line-height: 1;
            margin: 15px 0;
        }
    }
    .card-1 {
        bottom: 10%;
        left: 5%;
        width: 20%;
    }
    .card-2 {
        top: 20%;
        left: 18%;
        width: 20%;
    }
    .card-3 {
        bottom: 10%;
        left: 35%;
        width: 20%;
    }
    .card-4 {
        top: 20%;
        left: 50%;
        width: 20%;
    }
    .card-5 {
        bottom: 10%;
        left: 65%;
        width: 30%;
    }
    .card-6 {
        top: 20%;
        left: 74%;
        width: 25%;
    }
    .card-1,
    .card-2,
    .card-3,
    .card-4,
    .card-5,
    .card-6 {
        @media screen and (max-width: 992px) {
            width: 80%;
            height: auto;
            background-color: $White-Color;
            border-radius: 0.4em;
            padding: 5%;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            margin: 2.5% 0;
        }
    }
}
