.shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.9);
    background-color: $White-Color;
}
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
.close {
    height: 0;
}
.open {
    height: 300px;
}
.info-band {
    background-color: $theme-peacock;
    color: $White-Color;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    div {
        display: flex;
        align-items: center;
    }
    img {
        width: 1.5em;
        margin-right: 5px;
    }
}
.header-container {
    top: 0;
    left: 0;
    width: 100%;
    @media screen and (max-width: 992px) {
        background-color: $White-Color;
    }
    .header {
        display: flex;
        justify-content: space-between;
        padding: 0 3%;
        .logo {
            height: 60px;
            margin: 10px 0;
        }

        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .navigation-bar {
            display: flex;
            align-items: center;
            a {
                text-decoration: none;
                color: $theme-black;
                margin: 0 20px;
            }
            .dropdown {
                width: 0.8em;
                margin-left: 5px;
            }
            @media screen and (max-width: 992px) {
                flex-direction: column;
                align-items: flex-start;
                a {
                    margin: 10px;
                }
            }
        }
        .navigation-bar {
            .products {
                position: relative;
                cursor: pointer;
                .drop-down {
                    position: absolute;
                    top: 150%;
                    left: 0;
                    width: 25em;
                    height: auto;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                    border-radius: 0.5em;
                    padding: 10px 25px;
                    z-index: 10;
                    background-color: $White-Color;
                    @media screen and (max-width: 992px) {
                        left: -25%;
                        width: 25em;
                    }
                    .drop-down-list {
                        margin: 20px 0;
                        &:hover {
                            color: $theme-peacock;
                        }
                        .product-img {
                            width: 1.5em;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .signup,
            .login {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                border-radius: 0.4em;
                line-height: 1;
                img {
                    height: 1em;
                    margin-right: 5px;
                    @media screen and (max-width: 992px) {
                        display: none;
                    }
                }
            }
            .login {
                color: $theme-blue;
                @media screen and (max-width: 992px) {
                    padding: 0;
                }
            }
            .signup {
                color: $White-Color;
                background-color: $theme-blue;
                @media screen and (max-width: 992px) {
                    padding: 0;
                    background-color: transparent;
                    color: $theme-blue;
                }
            }
        }
        .navbar-container {
            width: 100%;
            overflow: hidden;
            transition: all 0.5s ease-in-out;
            background-color: $White-Color;
        }
        .menu-btn {
            position: absolute;
            right: 4%;
            margin-top: 20px;
            border-style: none;
            border: 1px solid $theme-peacock;
            background-color: $White-Color;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.2em;
            width: 3em;
            height: 3em;
            .menu-img {
                width: 2em;
            }
        }
    }
}
.intro-page {
    position: relative;
    .intro-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .carousel-control-next,
    .carousel-control-prev {
        width: 10%;
    }
    .carousel-inner {
        height: 100vh;
        .carousel-item {
            height: 100%;
            .content {
                position: absolute;
                left: 10%;
                top: 40%;
                color: black;
                width: 40%;
                height: 50%;
                .product-name {
                    color: $theme-peacock;
                    line-height: 1;
                }
                .subtitle {
                    margin: 10px 0 15px 0;
                }
                @media screen and (max-width: 992px) {
                    width: 70%;
                    left: 15%;
                    top: 70%;
                }
                @media screen and (max-width: 598px) {
                    width: 70%;
                    left: 15%;
                    top: 50%;
                }
            }
            .slider-img {
                position: absolute;
                right: 8%;
                top: 25%;
                width: 40%;
                @media screen and (max-width: 992px) {
                    top: 22%;
                    right: 10%;
                    width: 80%;
                }
                @media screen and (max-width: 598px) {
                    top: 22%;
                    right: 20%;
                    width: 60%;
                }
            }
        }
    }
}

.chart-container {
    padding-top: 15px;
    position: relative;
    .wrapper {
        min-height: 300px;
        position: absolute !important;
        background-color: transparent !important;
    }
    .panel-interviews-label-container {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        left: 50px;
        .label-dot {
            height: 12px;
            width: 22px;
            border-radius: 6px;
            background: linear-gradient(270deg, #11b2b8 1.47%, #133f7d 98.82%);
            margin-right: 5px;
        }
    }
}
.graphDropdown2 {
    background-color: #f6f7fb;
    color: #8780ab;
    width: auto;
    padding: 5px;
    position: absolute;
    right: 15px;
    top: 15px;
}
.graphDropdown {
    width: 145px;
}
.graphDropdownWidth {
    width: 160px;
}
.graphDropdownInput {
    .select__control {
        background-color: #f6f7fb;
        color: #8780ab;
        box-shadow: none;
    }
}
.pie-graph-box {
    width: 250px;
    margin: 20px auto;
}

.circular-graph-box {
    margin: 20px auto;
    display: block;
    width: 250px;
    height: 250px;
    text-align: center;
}

.circular-graph-box-small {
    width: 22%;
    padding-bottom: 15px;
    margin: 1% 4%;
}

.multi-graph-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
