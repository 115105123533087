.forgot-popup-main {
    left: 0;
    top: 0;
    background-color: $theme-popup-bg;
    .center-box {
        position: absolute;
        left: 30%;
        width: 70%;
        height: 100%;
        @media screen and (max-width: 992px) {
            width: 100%;
            left: 0;
        }
    }
    .forgot-box-content {
        @media screen and (max-width: 580px) {
            flex-direction: column;
        }
        .forgot-content {
            .forgot-content-title {
                color: $theme-peacock;
                line-height: 1;
                margin-top: 50px;
                margin-bottom: 10px;
                font-size: 70px;
                @media screen and (max-width: 700px) {
                    font-size: 50px;
                }
            }
            .forgot-content-btn {
                padding: 0.6em 2em;
                border-radius: 0.5em;
                margin: 0 auto;
            }
        }
    }
    .forgot-popup-box {
        width: 80%;
        background-color: $Back-ground;
        border-radius: 0.5em;
        @media screen and (max-width: 992px) {
            width: 90%;
        }
        .btn-close {
            height: 6px;
            width: 6px;
        }
        .forgot-popup-img {
            width: 40%;
            @media screen and (max-width: 580px) {
                width: 60%;
            }
        }
        .forgot-content {
            width: 60%;
            margin-left: 1em;
            @media screen and (max-width: 580px) {
                width: 90%;
                margin-left: 0;
            }
        }
        .sign-in-container {
            margin-top: 50px;
            width: 100%;
            text-align: end;
            @media screen and (max-width: 580px) {
                text-align: center;
            }
        }
    }
}

.sign-in-option {
    color: $theme-blue;
    font-weight: 700;
    cursor: pointer;
}

.forgot-input {
    width: 95%;
}
