.interviewer-hiw {
    padding: 50px 10px;
    .interviewer-img-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        .step-img {
            width: 15%;
            @media screen and (max-width: 992px) {
                width: 30%;
            }
        }
    }
}
.we-are-growing {
    background-color: $theme-light-peacock;
    padding: 50px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wag-card-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 598px) {
            flex-direction: column;
        }
        .wag-card {
            display: flex;
            flex-direction: column;
            border-bottom: 1.5px solid $theme-black;
            margin: 2.5%;
            width: 30%;
            @media screen and (max-width: 992px) {
                margin: 1%;
            }
            @media screen and (max-width: 598px) {
                margin: 2.5%;
                width: 40%;
            }
            .sub {
                margin-bottom: 15px;
            }
        }
    }
}
