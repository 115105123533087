.pay-success-container {
    background-color: $Back-ground;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pay-success-bg {
        width: 25em;
    }

    .pay-success-1 {
        color: $theme-peacock;
    }
    .pay-success-2 {
        color: $theme-blue;
        img {
            height: 1.5em;
            margin-right: 20px;
        }
    }
    .pay-success-3 {
        color: $theme-blue;
        margin-top: 10px;
    }
    .pay-success-4 {
        display: flex;
        margin: 25px 0;
        button {
            padding: 0.6em 2em;
            margin: 0 10px;
        }
    }
}
