.success-page {
    background-color: $theme-light-peacock;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .subtitle {
        width: 80%;
    }
    .img-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        border: 4px solid $theme-peacock;
        margin-top: 20px;
        border-radius: 1em;
        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            margin-top: 50px;
            padding: 10px 0;
        }
        img {
            margin: 2%;
            width: 10em;
            @media screen and (max-width: 992px) {
                width: 30%;
                margin: 1%;
            }
        }
    }
}
