.thankyou-signup {
    position: relative;
    .green-tick-big {
        height: 8em;
        margin: 30px 0;
    }
    .thankyou-signup-title {
        color: $theme-blue;
    }
    .thankyou-signup-subtitle {
        margin: 30px 0;
        text-align: center;
    }
    .thankyou-btn {
        padding: 0.5em 2em;
    }
}
