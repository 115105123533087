.support-box {
    width: 100%;
    height: 100%;
    border-top: 1.5px solid $theme-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: flex-start;
        border: none;
    }

    .form-box {
        border-right: 1px solid $theme-gray;
        width: 30%;
        height: 100%;
        @media screen and (max-width: 992px) {
            width: 90%;
            border: none;
        }

        .white-container {
            background-color: $White-Color;
            margin: 1em 1em 0 0;
            padding: 1em;
            border-radius: 1em;
            .support-message-box {
                resize: none !important;
                border: 1px solid $theme-gray;
                border-radius: 0.5em;
                width: 100%;
                height: 100px; /* Adjust height as needed */
                box-sizing: border-box;
                overflow: auto;
            }
            .btn-container {
                display: flex;
                flex-wrap: wrap;
                button {
                    width: 135px;
                    margin: 5px 5px;
                }
                @media screen and (max-width: 992px) {
                    flex-direction: column;
                    align-items: center;
                    button {
                        margin: 5px 0;
                    }
                }
                .common-btn {
                    padding: 0.6em 0.6em;
                }
                .call-back {
                    border: 1px dashed $theme-blue;
                    padding: 0.6em 0.6em;
                    border-radius: 0.4em;
                    color: $theme-blue;
                    background-color: $White-Color;
                }
            }
        }
    }
    .table-box {
        border-left: 1px solid $theme-gray;
        width: 70%;
        height: 100%;
        @media screen and (max-width: 992px) {
            width: 90%;
            overflow-x: auto;
            border: none;
        }

        .white-container {
            background-color: $White-Color;
            margin: 1em;
            padding: 0.5em 0;
            border-radius: 1em;
            height: calc(100% - 80px);

            .w-15 {
                width: 15%;
            }
            .w-20 {
                width: 20%;
            }
            .w-10 {
                width: 10%;
            }

            .title {
                text-align: center;
                color: $theme-blue;
                margin-bottom: 0.5em;
                @media screen and (max-width: 992px) {
                    width: 992px;
                }
            }
            .support-table-container {
                height: calc(100% - 75px);
                overflow-x: auto;
                .header {
                    min-width: 700px;
                    background-color: $theme-light-blue;
                    padding: 15px 0;
                    color: $theme-subuser;
                    border-radius: 0.5em;
                    margin: 5px 20px 5px 10px;
                    @media screen and (max-width: 992px) {
                        width: 992px;
                    }
                }
                .list-box {
                    min-width: 700px;
                    margin: 0 10px;
                    padding-right: 10px;
                    height: calc(100% - 75px);
                    overflow-y: auto;
                    @media screen and (max-width: 992px) {
                        width: 992px;
                    }
                    .list {
                        display: flex;
                        align-items: center;
                        padding: 12px 0;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                        margin-bottom: 5px;
                        border-radius: 0.5em;
                        // .open-state {
                        //   color: $theme-orange;
                        //   background-color: $theme-light-oragne;
                        // }
                        // .ack {
                        //   color: $theme-subuser;
                        //   background-color: $theme-google;
                        // }
                        // .solved {
                        //   color: $theme-light-green;
                        //   background-color: $theme-green-background;
                        // }
                        .ticket {
                            color: $White-Color;
                            background-color: $theme-blue;
                            padding: 0.5em 1em;
                            border-radius: 0.4em;
                        }
                        .status {
                            padding: 5px;
                            width: 100px;
                            text-align: center;
                            border-radius: 0.4em;
                        }
                        .message {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: center;
                        }
                        .action-box {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .action-btn {
                                border-style: none;
                                width: 30px;
                                height: 30px;
                                background-color: $theme-light-blue;
                                color: $theme-subuser;
                                img {
                                    height: auto;
                                    width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sp-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

    .popup-box {
        width: 50%;
        height: auto;
        background-color: $White-Color;
        border-radius: 1em;
        padding: 2em;

        .title {
            line-height: 1;
            h3 {
                margin: 0;
            }
            .status {
                padding: 8px;
                margin-left: 10px;
                width: 100px;
                text-align: center;
            }
            .close-btn {
                img {
                    height: 1em;
                }
            }
        }
        .input-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .input-field {
                width: 48%;
                margin: 1% 0;
            }
            .textarea {
                width: 100%;
            }
            .support-popup-remarks {
                resize: none !important;
                height: 150px;
            }
            .btn-container {
                margin-top: 2em;
                button {
                    padding: 0.5em 1em;
                }
            }
            a {
                text-decoration: none;
                color: $theme-blue;
                cursor: pointer;
            }
        }
    }
}

.open-state {
    color: $theme-orange;
    background-color: $theme-light-oragne;
}
.acknowledge {
    color: $theme-subuser;
    background-color: $theme-google;
}
.solved {
    color: $theme-light-green;
    background-color: $theme-green-background;
}
.closed {
    color: $theme-red;
    background-color: $theme-light-red;
}

.support-box .white-container .header img {
    padding-left: 5px;
}
