body {
    margin: 0 !important;
    font-family: 'Manrope-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
}
code {
    font-family: 'Manrope-Regular';
}
@page {
    size: A4;
    margin: 0mm;
}

.feedback-type-1 {
    .rating-system {
        display: flex;
        height: 60px;
        justify-content: space-evenly;
        margin: 40px 0;
    }
    .resume-summary {
        font-size: 15px !important;
        padding: 20px !important;
        text-align: left !important;
    }
    .profile-div,
    .profile-img-div {
        align-items: center;
        display: flex;
    }

    .code-snippet-header,
    .screening-ques-header,
    .screenshots-header,
    .soft-skills-div {
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
    }

    body {
        margin: 0 !important;
        font-family: 'Manrope-Regular';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-wrap: break-word;
    }

    code {
        font-family: 'Manrope-Regular';
    }

    .graph-img {
        width: 120px;
    }

    .feedback-footer,
    .footer {
        display: flex;
    }

    .graph-descp-comment,
    .graph-title {
        font-size: 18px;
        line-height: 28px;
    }

    .feedback-status {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .soft-skill-header-type-1 {
        transform: rotate(270deg);
        white-space: nowrap;
    }

    .optional-skills-header,
    .psycological-skill-header,
    .soft-skill-header,
    .soft-skill-header-type-2 {
        transform: rotate(90deg);
        white-space: nowrap;
    }

    .radar-graph-labels-main {
        text-align: left;
        width: 120px;
        margin: 0 auto;
    }

    .profile-div {
        background: #133f7d;
        min-height: 270px;
    }

    .profile-img-div {
        background: #d7eeff;
        width: 220px;
        height: 270px;
        margin: 0 25px;
    }

    .graph-title {
        margin: 6px 0 !important;
    }

    .profile-img-logo {
        width: 220px;
        height: 220px;
        border-radius: 50%;
    }

    .common-profile-text {
        color: #fff;
        font-style: normal;
    }

    .profile-details-text {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        display: flex;
    }

    .radar-graph-label-color {
        border-radius: 2px;
        height: 15px;
        margin-right: 10px;
        width: 15px;
        display: inline-block;
    }

    .radar-graph-label-name {
        display: inline-block;
        width: 90px;
    }

    .blue {
        background-color: rgba(19, 63, 125, 0.4);
    }

    .newgreen {
        background-color: rgba(17, 178, 184, 0.4);
    }

    .profile-details-status {
        position: relative;
        width: 100%;
        height: 220px;
    }

    .profile-contact {
        font-weight: 100;
        font-size: 15px;
        line-height: 22px;
        display: flex;
    }

    .profile-intId {
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
    }

    .profile-recommended-img {
        position: absolute;
        width: 300px;
        right: 0;
        top: 15px;
        height: auto;
    }

    .CircularProgressbarDiv {
        float: right;
        width: 300px;
    }

    .profile-recommended {
        font-weight: 600;
        color: #fff !important;
        font-size: 20px;
        text-align: right;
        letter-spacing: 0.04em;
        width: 400px;
        position: absolute;
        line-height: 42.2px;
        top: 15px;
        right: 50px;
    }

    .detailed-feedback-title,
    .feedback-header {
        font-weight: 700;
        line-height: 66px;
        text-align: center;
        color: #133f7d;
        letter-spacing: 0.04em;
        font-style: normal;
    }

    .profile-details-div {
        width: 100%;
        padding: 10px;
    }

    .profile-suspected {
        height: auto;
        width: 120px;
        position: absolute;
        left: 0;
        bottom: 20px;
    }

    .detailed-feedback-title {
        font-size: 48px;
    }

    .feedback-wrapper,
    .report-wrapper {
        background-color: #f5f5f5;
    }

    .pagebreak {
        page-break-before: always;
        padding: 10px;
        page-break-before: always;
    }

    .feedback-header {
        padding-top: 40px;
        font-size: 2.8vw;
    }

    .font-style-detailed-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.04em;
        color: #000;
    }

    .font-style-header-detailed {
        background-color: #133f7d;
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vw;
        line-height: 44px;
        letter-spacing: 0.04em;
        color: #fff;
    }

    .mandatory-skills-wrapper {
        margin: 20px;
        background-color: #fff;
        display: flex;
    }

    .mandatory-skills-outer-wrapper,
    .optional-skills-outer-wrapper {
        background-color: #133f7d;
        min-height: 400px;
        width: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .final-remark-header,
    .title-1 {
        transform: rotate(-90deg);
        white-space: nowrap;
    }

    .title-600 {
        padding: 10px;
        display: none !important;
    }

    .mandatory-skills-content {
        padding: 10px 10px 10px 30px;
        width: 92%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
    }

    .opyional-skills-wrapper {
        background-color: #fff;
        margin: 20px;
        display: flex;
    }

    .opyional-skills-content {
        padding-left: 30px;
        padding-top: 10px;
        width: 92%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
    }

    .optional-skills-outer-wrapper-600 {
        display: none !important;
        padding: 10px;
    }

    .container-1 {
        border: 2px solid red;
        display: flex;
        height: 400px;
        width: 100%;
    }

    .con-1 {
        border: 2px solid #00f;
        height: 100%;
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .con-2 {
        border: 2px solid green;
        height: 100%;
        width: 85%;
    }

    .font-styling-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #000;
    }

    .code-snippet-span,
    .font-style-header-code-snippet {
        font-weight: 600;
        font-style: normal;
        line-height: 44px;
        letter-spacing: 0.04em;
    }

    .font-style-header-code-snippet {
        background-color: #133f7d;
        font-size: 2.2vw;
        text-align: center;
        color: #fff;
    }

    .code-snippet-wrapper {
        background-color: #f5f5f5;
        padding-bottom: 20px;
    }

    .code-snippet-header {
        background-color: #133f7d;
        color: #fff;
        margin-top: 24px;
        width: 45%;
        padding: 10px 10px 10px 7%;
    }

    .code-snippet-span {
        background-color: #133f7d;
        font-size: 2.8vw;
        color: #fff;
    }

    .final-remark-content-span,
    .font-style-soft-skill {
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.04em;
        color: #000;
    }

    .code-content {
        background: #d9d9d9;
        border-radius: 50px;
        margin: 20px;
        padding: 40px 60px 40px 89px;
    }

    .code-content-pre {
        width: 100%;
        overflow-x: auto;
    }

    .final-remark-wrapper,
    .psycological-skill-wrapper {
        margin: 20px;
        padding: 10px;
        display: flex;
    }

    .soft-skill-header-outer,
    .vertical-left-header {
        background-color: #133f7d;
        min-height: 300px;
        width: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .final-remark-header-600,
    .psycological-skill-header-600 {
        display: none !important;
        padding: 5px;
    }

    .final-remark-content {
        background-color: #fff;
        padding-left: 10px;
        width: 92%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
    }

    .final-remark-content-span {
        font-size: 14px;
        padding: 20px;
    }

    .screenshots-header {
        background-color: #133f7d;
        padding: 10px 10px 10px 7%;
        width: 40%;
    }

    .screenshots {
        height: 100%;
        width: 100%;
    }

    .footer-logo {
        height: 69px;
        margin: auto;
        width: auto;
    }

    .screenshot-wrapper,
    .screenshot-wrapper-code {
        padding-top: 20px;
        position: relative;
        margin: 0 4%;
        text-align: center;
        display: inline-block;
        width: 42%;
    }

    .screenshot-wrapper img,
    .screenshot-wrapper-code img {
        width: 100%;
    }

    .fill-img1 {
        height: 72%;
        left: 12%;
        position: absolute;
        top: 12%;
        width: 76%;
    }

    .btn-g,
    .recording-btn-g,
    .section {
        position: relative;
    }

    .App,
    .wrapper {
        background: #f5f5f5;
    }

    .font-style-soft-skill {
        font-size: 16px;
        margin: 10px 0;
    }

    .font-style-header,
    .font-style-header-screening,
    .font-style-header-soft-skill,
    .phychological-skills-spanv2 {
        line-height: 44px;
        color: #fff;
        letter-spacing: 0.04em;
        font-style: normal;
    }

    .font-style-header-soft-skill {
        font-weight: 600;
        font-size: 2.2vw;
        background: #133f7d;
    }

    .soft-skill-wrapper,
    .soft-skill-wrapperv2 {
        margin: 20px;
        display: flex;
        min-height: 400px;
    }

    .soft-skill-header-600 {
        display: none;
        padding: 5px;
    }

    .soft-skill-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 10px 10px 30px;
        text-align: start;
        width: 92%;
        background: #fff;
    }

    .soft-skill-header-inner {
        padding: 50% 0;
        margin: 0;
    }

    .psycological-skill-content {
        padding: 15px 10px 22px;
        background-color: #fff;
        width: 92%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
    }

    .psycological-skill-header-outer {
        background-color: #133f7d;
        min-height: 350px;
        width: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .screening-ques-wrapper {
        margin: 24px 0;
        background-color: #f5f5f5;
        padding-bottom: 1px;
    }

    .font-style-header-screening {
        font-weight: 600;
        font-size: 2.8vw;
    }

    .screening-ques-header {
        background-color: #133f7d;
        padding: 10px 10px 10px 7%;
        text-align: left;
        width: 45%;
    }

    .ans,
    .ques {
        padding: 15px;
    }

    .ques-ans-wrapper {
        margin: 20px 40px;
    }

    .ques {
        background: #cadff5;
        width: 70%;
        font-weight: 700;
    }

    .ans,
    .blank-div {
        background-color: #fff;
    }

    .ans {
        margin: 15px 0;
    }

    .header {
        display: flex;
        padding: 10px 30px;
    }

    * {
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    .pagebreak-after {
        page-break-after: always;
    }

    .img-logo {
        height: 60px;
        width: auto;
    }

    .text-center {
        text-align: center !important;
    }

    .img-div {
        width: 50%;
    }

    .header-content-wrapper {
        display: flex;
        width: 50%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }

    .blank-div {
        height: 72px;
        width: 100%;
    }

    .font-style-header,
    .phychological-skills-spanv2 {
        font-weight: 600;
        font-size: 32px;
        text-align: right;
    }

    .font-style {
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        letter-spacing: 0.04em;
        color: #000;
    }

    .font-style-profile-span,
    .font-syle-profile {
        letter-spacing: 0;
        font-size: 14px;
        line-height: 27px;
    }

    .content-div {
        height: 24%;
        width: 100%;
        background-color: #133f7d;
        padding: 10px 10px 10px 9%;
        clip-path: polygon(100% 100%, 0% 100%, 10% 0%, 100% 0%);
    }

    .content-div-span {
        margin-left: 15%;
    }

    .btn-g {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #fff;
        background: #11b2b8;
        border-radius: 5px;
        border: none;
        right: 10px;
        width: 80px;
    }

    .recording-btn-g {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: #133f7d;
        background: #ffffff;
        border-radius: 5px;
        border: none;
        width: 118px;
        height: 30px;
    }
    .section {
        display: flex;
        justify-content: space-between;
    }

    .profile,
    .profile-details {
        flex-direction: column;
        display: flex;
    }

    .tech-details-wrapper {
        display: flex;
        width: 50%;
    }

    .tech-details {
        width: 50%;
        position: absolute;
        top: 36%;
        left: 2%;
    }

    .profile {
        position: relative;
        align-items: flex-end;
        padding-top: 30px;
    }

    .font-syle-profile {
        font-weight: 500;
        text-align: left;
    }

    .font-style-profile-span {
        font-weight: 600;
        color: #626262;
    }

    .profile-details {
        position: absolute;
        top: 77%;
        left: 21%;
        align-items: center;
        padding-top: 7px;
    }

    .profile-details-p1 {
        color: #626262;
    }

    .profile-details-p2 {
        color: #133f7d;
        font-weight: 1000;
        font-size: 16px;
    }

    .profile-details-p3 {
        color: #000;
        font-weight: 600;
    }

    .arrow-div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 25px;
        margin-top: 27px;
    }

    .arrow-img {
        position: relative;
        right: 22%;
        width: 6%;
    }

    .recommendation {
        display: flex;
        margin-top: 5px;
    }

    .recommendation-span {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6vw;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.04em;
        display: table-cell;
        vertical-align: middle;
        color: #fff;
    }

    .recommendation-div {
        width: 27%;
        height: 46px;
        display: table;
        border-radius: 13px;
        position: absolute;
        bottom: 50;
    }

    .red {
        background-color: #e96f68;
        left: 10%;
        z-index: 2;
    }

    .yellow {
        left: 35%;
        background: #f5e659;
        z-index: 1;
    }

    .green {
        margin-left: 59%;
        z-index: 0;
        background: #61be52;
    }

    .candidate-overall-skills {
        display: flex;
        margin-top: 88px;
    }

    .candidate-skills-wrapper {
        background: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 0 50px 50px 0;
        text-align: center;
        width: 50%;
        height: 550px;
    }

    .toplayer-ellip-img {
        width: 120px;
        height: 120px;
    }

    .candidate-skills {
        height: 100%;
        width: 100%;
        text-align: center;
        margin: 15px auto;
        display: flex;
        max-width: 450px;
        max-height: 450px;
        align-items: center;
        justify-content: center;
    }

    .overall-skills-div {
        display: flex;
        min-height: 550px;
        align-items: center;
        justify-content: center;
        margin: 60px 0;
    }

    .candidate-skills-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.04em;
        color: #133f7d;
        padding-top: 20px;
        display: block;
    }

    .overAll-skills-header {
        width: 100%;
        height: 70px;
        clip-path: polygon(0 2%, 19% 197%, 353% 0%, 0% 0%);
        position: relative;
        background-color: #133f7d;
        justify-content: flex-end;
        align-items: center;
        display: flex;
    }

    .overAll-skills {
        width: 50%;
        height: 550px;
    }

    .mobile-mode {
        display: none;
    }

    .desktop-mode {
        display: block;
    }

    .leadership-skills-span,
    .overAll-skills-span,
    .phychological-skills-span {
        margin-right: 10%;
    }

    .toplayer-ellip-percent,
    .toplayer-ellip-title {
        margin: 2.5px 5px;
        font-weight: 600;
    }

    .CircularProgressbar {
        width: 100px;
        text-align: center;
    }

    .CircularProgressbarDiv {
        position: relative;
        text-align: center;
        top: 80px;
    }

    .CircularProgressbarText {
        color: #fff;
        position: absolute;
        right: 46%;
        text-align: center;
        top: 14px;
        font-size: 30px;
    }

    .CircularProgressbarText p {
        margin: 0;
        font-size: 14px;
    }

    .toplayer-ellip-div {
        padding-top: 25px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .marking-rating-span,
    .toplayer-ellip-percent {
        color: #133f7d;
    }

    .font-style-percent {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0;
    }

    .vector-wrapper {
        display: flex;
        position: relative;
        justify-content: center;
    }

    .vector-tl-div {
        width: 220px;
    }

    .vector-center {
        vertical-align: middle;
        margin-top: -160px;
    }

    .secondlayer-ellip {
        margin-top: 10px;
        text-align: center;
    }

    .vector-center-div {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .vector-center {
        width: 140px;
    }

    .secondlayer-ellip-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: red;
    }

    .secondlayer-ellip-right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ellip-layer-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .soft-phychological-skills {
        background-color: #f5f5f5;
        margin-top: 60px;
        display: flex;
    }

    .chart-wrapper {
        width: 200px;
        height: 200px;
        display: inline-block;
    }

    .rating-div {
        width: 150px;
        overflow: clip;
        display: inline-block;
        margin-left: 30px;
    }

    .soft-skills {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m1-img,
    .m2-img,
    .m3-img {
        position: absolute;
    }

    .m1-img {
        left: 40%;
        top: 10%;
        width: 80px;
    }

    .m2-img {
        top: 46%;
        left: 46%;
        width: 57px;
    }

    .m3-img {
        left: 33%;
        top: 75%;
        width: 105px;
    }

    .marking-rating-name,
    .marking-rating-namev2 {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: #000;
        margin: 10px 5px;
        text-align: start;
        height: 50px;
        display: block;
    }

    .leadership-skills-wrapper,
    .phychological-skills-wrapper,
    .soft-skills-wrapper {
        width: 49%;
        display: inline-block;
    }

    .space-2 {
        margin: 40px;
        display: flex;
    }

    .leadership-skills-wrapperv2,
    .phychological-skills-wrapperv2,
    .soft-skills-wrapperv2 {
        width: 32%;
        display: inline-block;
    }

    .soft-skills-graph-wrapper {
        background-color: #e7ebfb;
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .screenshots-container {
        display: block;
        text-align: center;
    }

    .leadership-skills-div,
    .soft-skills-div {
        background-color: #133f7d;
        align-items: center;
        display: flex;
        width: 100%;
        height: 70px;
    }

    .leadership-skills-span,
    .phychological-skills-span,
    .soft-skills-span {
        font-size: 32px;
        text-align: right;
        color: #fff;
        line-height: 44px;
        letter-spacing: 0.04em;
        font-style: normal;
        font-weight: 600;
    }

    .leadership-skills-div {
        clip-path: polygon(0% 100%, 15% 0%, 100% 0%, 100% 100%, 0% 100%);
        justify-content: flex-end;
    }

    .phychological-skills-divv2,
    .skills-div {
        align-items: center;
        height: 70px;
        background-color: #133f7d;
        display: flex;
    }

    .phychological-skills-divv2 {
        clip-path: polygon(0% 0%, 12% 100%, 100% 100%, 88% 100%, 100% 0%);
        position: relative;
        width: 130%;
        right: 15%;
        justify-content: center;
    }

    .skills-div {
        clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        width: 50%;
    }

    .soft-skills-span {
        margin-left: 10%;
    }

    .phychological-skills-div {
        clip-path: polygon(0% 0%, 13% 100%, 100% 100%, 100% 0%);
        position: relative;
        background-color: #133f7d;
        align-items: center;
        display: flex;
        height: 70px;
        width: 115%;
        right: 15%;
        justify-content: flex-end;
    }

    .other-skills,
    .other-skillsv2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
    }

    .code-snippet-wrapper,
    .detailed-feedback,
    .detailed-feedback-list,
    .screening-ques-wrapper,
    .skill-rating-div {
        margin-bottom: 60px;
    }

    .psychological-skills-span {
        color: #fff;
        background-color: #133f7d;
        font-style: normal;
        font-weight: 600;
        font-size: 2.8vw;
        line-height: 44px;
        letter-spacing: 0.04em;
        margin: 10px 8% 10px 10px;
    }

    .profilepic {
        width: 90%;
    }

    .rating-button {
        border-radius: 13px;
        margin: 10px !important;
        padding: 20px !important;
    }

    .rating-arrow {
        border: 1.5px solid #133f7d;
        height: 100%;
        width: 10px;
        border-radius: 10px;
        position: relative;
    }

    .rating-arrow-tab {
        top: 80%;
        width: 35px;
        height: 25px;
        position: absolute;
    }

    .rating-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.04em;
    }

    .mobile-recommendation-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;
    }

    .recommendation-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #626262;
    }

    .secondlayer-ellip .ellip-div {
        width: 160px;
        display: inline-block;
    }

    .toplayer-ellip {
        padding-top: 40px;
        text-align: center;
    }

    .individual-graph-wrapper {
        width: 25%;
        display: inline-block;
        padding: 40px 20px;
        text-align: center;
    }

    .mandatory-skills-graph-wrapper {
        background: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 76px;
        margin: 25px;
        padding: 20px;
        text-align: center;
    }

    .graph-title {
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #000;
    }

    .graph-descp-comment {
        font-style: normal;
        font-weight: 600;
    }

    .other-skillsv2 .m1-img {
        left: 150px;
        top: 12%;
        width: 54px;
    }

    .other-skillsv2 .m2-img {
        left: 173px;
        top: 45%;
        width: 31px;
    }

    .other-skillsv2 .m3-img {
        position: absolute;
        width: 82px;
        top: 75%;
    }

    .status-green {
        color: #61be52;
    }

    .status-yellow {
        color: #fc8c43;
    }

    .status-red {
        color: #e96f68;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        body,
        html {
            width: 210mm;
            height: 297mm;
        }

        .rating-system {
            height: unset !important;
        }

        .code-content-pre {
            overflow: hidden;
        }

        .screenshot-wrapper-code {
            width: 82% !important;
        }

        .break-stop {
            page-break-inside: avoid;
            break-inside: avoid;
            padding: 5px;
        }

        .feedback-status {
            font-size: 12px;
            line-height: 20px;
        }

        .App {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: #f5f5f5;
            page-break-after: always;
        }

        .rating-div2 {
            display: inline-block;
            margin-left: 0;
            overflow: clip;
            width: 100px;
        }

        .btn-g,
        .mobile-mode,
        .soft-skill-header-outer {
            display: none;
        }

        .recording-btn-g {
            display: none !important;
        }

        .code-snippet-wrapper,
        .detailed-feedback,
        .screenshots-wrapper,
        .skill-rating-div div {
            margin-top: 12px;
        }

        .img-logo {
            width: auto;
            height: 59px;
        }

        .pagebreak {
            page-break-before: always;
        }

        .soft-skills-graph-wrapper {
            height: 220px;
        }

        .profile-div {
            min-height: 220px;
        }

        .overAll-skills-header {
            height: 50px;
        }

        .m1-img {
            position: absolute;
            left: 45%;
            top: 15%;
            width: 80px;
        }

        .desktop-mode {
            display: block;
        }

        .candidate-skills canvas {
            height: 300px !important;
            width: 300px !important;
        }

        .profile-img-div {
            width: 170px;
            height: 220px;
        }

        .toplayer-ellip-img {
            width: 80px;
            height: 80px;
        }

        .profile-img-logo {
            width: 170px;
            height: 170px;
        }

        .profile-details-status {
            height: 170px;
        }

        .profile-suspected {
            width: 90px;
            height: auto;
            right: 50%;
            bottom: 20px;
            border: none;
        }

        .code-content-pre {
            overflow-x: hidden;
        }

        .screenshots-container {
            display: block;
            text-align: center;
        }

        .screenshot-wrapper,
        .screenshot-wrapper-code {
            display: inline-block;
        }

        .candidate-skills {
            width: 350px;
            max-width: 350px;
            max-height: 350px;
        }

        .CircularProgressbarDiv,
        .profile-recommended-img {
            width: 240px;
        }

        .profile-recommended {
            font-size: 14px;
            width: 250px;
            line-height: 36.93px;
        }

        .candidate-skills-wrapper {
            height: 450px;
            width: 50%;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            border-radius: 0 25px 25px 0;
        }

        .mandatory-skills-graph-wrapper {
            border-radius: 25px;
        }

        .overall-skills-div {
            min-height: 500px;
            margin: 0;
        }

        .soft-skill-content {
            padding: 10px 20px;
            width: 100%;
        }

        .secondlayer-ellip .ellip-div,
        .vector-center {
            width: 120px !important;
        }

        .candidate-skills {
            height: 80%;
        }

        .space-2 {
            margin: 10px;
        }

        .vector-center {
            margin-top: -100px !important;
        }

        .vector-tl {
            width: 30px;
        }

        .vector-tl-div {
            width: 160px;
        }

        .secondlayer-ellip,
        .vector-wrapper {
            margin-top: 10px;
        }

        .leadership-skills-span,
        .phychological-skills-span,
        .phychological-skills-spanv2,
        .soft-skills-span {
            font-size: 18px;
        }

        .candidate-skills-heading,
        .font-style-header {
            font-size: 24px;
            line-height: 30px;
        }

        .chart-wrapper,
        .chart-wrapper canvas {
            width: 150px !important;
            height: 150px !important;
            display: inline-block;
        }

        .overAll-skills {
            height: 450px;
        }

        .font-style-percent {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }

        .leadership-skills-div,
        .phychological-skills-div,
        .phychological-skills-divv2,
        .skills-div,
        .soft-skills-div {
            height: 40px;
        }

        .graph-img {
            width: 80px;
        }

        .graph-descp-comment,
        .graph-title {
            font-size: 14px;
            line-height: 22px;
        }

        .individual-graph-wrapper {
            display: inline-block;
            padding: 1.5%;
            text-align: center;
            width: 23%;
        }

        .detailed-feedback-title {
            font-size: 24px;
            line-height: 33px;
        }

        .soft-skill-header {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.04em;
        }

        .font-style-soft-skill {
            font-size: 13px;
            letter-spacing: 0.04em;
        }

        .code-content {
            font-size: 14px;
            line-height: 19px;
        }

        .soft-skill-wrapper,
        .soft-skill-wrapperv2 {
            min-height: 250px;
            display: block;
        }

        .soft-skill-header-600 {
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .profile-details-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        .profile-intId {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
        }

        .profile-contact {
            font-weight: 100;
            font-size: 15px;
            line-height: 22px;
            display: flex;
        }

        .code-snippet-wrapper,
        .detailed-feedback,
        .detailed-feedback-list,
        .screening-ques-wrapper,
        .skill-rating-div {
            margin-bottom: 0;
        }

        .other-skillsv2 .m1-img {
            left: 115px;
            top: 15%;
            -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
            width: 38px;
        }

        .other-skillsv2 .m2-img {
            left: 128px;
            top: 45%;
            width: 25px;
        }

        .other-skillsv2 .m3-img {
            left: 109px;
            top: 71%;
            width: 47px;
            -webkit-transform: rotate(-40deg);
            transform: rotate(40deg);
        }

        .other-skills .m1-img {
            left: 37%;
            top: 23%;
            width: 60px;
        }

        .other-skills .m2-img {
            left: 39%;
            top: 46%;
            width: 53px;
        }

        .other-skills .m3-img {
            left: 26%;
            top: 71%;
            width: 95px;
        }

        .CircularProgressbar {
            width: 80px;
            text-align: center;
        }

        .CircularProgressbarDiv {
            position: relative;
            text-align: center;
            top: 70px;
        }

        .CircularProgressbarText {
            color: #fff;
            position: absolute;
            right: 46%;
            text-align: center;
            top: 12px;
            font-size: 24px;
        }

        .CircularProgressbarText p {
            margin: 0;
            font-size: 10px;
        }

        .resume-summary {
            font-size: 12px !important;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        .leadership-skills-div,
        .phychological-skills-div,
        .phychological-skills-divv2 {
            clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        }

        .code-snippet-wrapper,
        .detailed-feedback,
        .screenshots-wrapper,
        .skill-rating-div div {
            margin-top: 15px;
        }

        .phychological-skills-div,
        .phychological-skills-divv2 {
            width: 60%;
            margin-bottom: 10px;
            background-color: #133f7d;
            align-items: center;
            display: flex;
            left: 0;
            justify-content: flex-start;
        }

        .leadership-skills-span {
            margin-left: 10%;
            margin-right: auto;
        }

        .phychological-skills-span,
        .phychological-skills-spanv2 {
            margin-left: 10%;
            text-align: left;
        }

        .img-logo {
            width: auto;
            height: 60px;
        }
        .white-label {
            width: 70px;
            height: 59px;
        }
        .pagebreak {
            page-break-before: always;
        }

        .soft-skills-graph-wrapper {
            height: 220px;
        }

        .profile-div {
            min-height: 220px;
        }

        .overAll-skills-header {
            height: 50px;
        }

        .candidate-skills canvas {
            height: 300px !important;
            width: 300px !important;
        }

        .profile-img-div {
            width: 170px;
            height: 220px;
        }

        .toplayer-ellip-img {
            width: 80px;
            height: 80px;
        }

        .profile-img-logo {
            width: 170px;
            height: 170px;
        }

        .profile-details-status {
            height: 170px;
        }

        .profile-suspected {
            width: 90px;
            height: auto;
            right: 50%;
            bottom: 20px;
        }

        .code-content-pre {
            overflow-x: hidden;
        }

        .screenshots-container {
            display: block;
            text-align: center;
        }

        .screenshot-wrapper,
        .screenshot-wrapper-code {
            width: 50%;
            display: inline-block;
        }

        .candidate-skills {
            width: 350px;
            max-width: 350px;
            max-height: 350px;
        }

        .CircularProgressbarDiv,
        .profile-recommended-img {
            width: 240px;
        }

        .profile-recommended {
            font-size: 16px;
            width: 280px;
            line-height: 36.93px;
        }

        .candidate-skills-wrapper {
            height: 450px;
            width: 50%;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            border-radius: 0 25px 25px 0;
        }

        .secondlayer-ellip .ellip-div,
        .vector-center {
            width: 120px !important;
        }

        .mandatory-skills-graph-wrapper {
            border-radius: 25px;
        }

        .overall-skills-div {
            min-height: 500px;
            margin: 0;
        }

        .candidate-skills {
            height: 80%;
        }

        .space-2 {
            margin: 10px;
        }

        .vector-center {
            margin-top: -100px !important;
        }

        .vector-tl {
            width: 30px;
        }

        .vector-tl-div {
            width: 160px;
        }

        .secondlayer-ellip,
        .vector-wrapper {
            margin-top: 10px;
        }

        .leadership-skills-span,
        .phychological-skills-span,
        .phychological-skills-spanv2,
        .soft-skills-span {
            font-size: 18px;
        }

        .candidate-skills-heading,
        .font-style-header {
            font-size: 24px;
            line-height: 30px;
        }

        .overAll-skills {
            height: 450px;
        }

        .font-style-percent {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }

        .leadership-skills-div,
        .phychological-skills-div,
        .phychological-skills-divv2,
        .skills-div,
        .soft-skills-div {
            height: 40px;
        }

        .graph-img {
            width: 80px;
        }

        .graph-descp-comment,
        .graph-title {
            font-size: 14px;
            line-height: 22px;
        }

        .individual-graph-wrapper {
            display: inline-block;
            padding: 2%;
            text-align: center;
            width: 23%;
        }

        .detailed-feedback-title {
            font-size: 24px;
            line-height: 33px;
        }

        .soft-skill-header {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.04em;
        }

        .font-style-soft-skill {
            font-size: 16px;
            letter-spacing: 0.04em;
        }

        .code-content {
            font-size: 14px;
            line-height: 19px;
        }

        .soft-skill-wrapper,
        .soft-skill-wrapperv2 {
            min-height: 300px;
        }

        .profile-details-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        .profile-intId {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
        }

        .profile-contact {
            font-weight: 100;
            font-size: 15px;
            line-height: 22px;
            display: flex;
        }

        .code-snippet-wrapper,
        .detailed-feedback,
        .detailed-feedback-list,
        .screening-ques-wrapper,
        .skill-rating-div {
            margin-bottom: 0;
        }

        .leadership-skills-wrapperv2,
        .soft-skills-wrapper,
        .soft-skills-wrapperv2 {
            width: 100%;
            display: inline-block;
            margin: 20px 0;
        }

        .phychological-skills-wrapper,
        .phychological-skills-wrapperv2 {
            width: 100%;
            display: inline-block;
        }

        .other-skills,
        .other-skillsv2 {
            display: block;
            margin-bottom: 0;
        }

        .rating-div2 {
            display: inline-block;
            margin-left: 30px;
            overflow: clip;
            width: 180px;
        }

        .leadership-skills-div,
        .soft-skills-div {
            height: 40px;
            width: 60%;
            margin-bottom: 10px;
        }

        .other-skillsv2 .m1-img {
            width: 80px;
            top: 9%;
        }

        .other-skillsv2 .m2-img {
            width: 57px;
        }

        .other-skillsv2 .m3-img {
            top: 77%;
            width: 109px;
        }

        .CircularProgressbar {
            width: 80px;
            text-align: center;
        }

        .CircularProgressbarDiv {
            position: relative;
            text-align: center;
            top: 70px;
        }

        .CircularProgressbarText {
            color: #fff;
            position: absolute;
            right: 46%;
            text-align: center;
            top: 12px;
            font-size: 24px;
        }

        .CircularProgressbarText p {
            margin: 0;
            font-size: 10px;
        }

        .mobile-mode {
            display: none;
        }

        .desktop-mode {
            display: block;
        }

        .feedback-status {
            font-size: 12px;
            line-height: 20px;
        }

        .resume-summary {
            font-size: 12px !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .leadership-skills-div,
        .phychological-skills-div,
        .phychological-skills-divv2 {
            clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 100% 100%, 0% 100%);
        }

        .btn-g {
            width: 46px;
            height: 22px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            right: 0;
        }

        .recording-btn-g {
            width: 100px;
            height: 30px;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            right: 0;
            color: #ffffff;
            background-color: #133f7d;
        }

        .mobile-mode {
            display: block !important;
        }

        .profile-details-div-1 {
            margin: 0 15%;
        }

        .desktop-mode {
            display: none !important;
        }

        .skills-div {
            display: block;
        }

        .skills-div .soft-skills-span {
            margin-left: 0;
        }

        .phychological-skills-div,
        .phychological-skills-divv2 {
            height: 40px;
            width: 60%;
            margin-bottom: 10px;
            background-color: #133f7d;
            align-items: center;
            display: flex;
            left: 0;
            justify-content: flex-start;
        }

        .leadership-skills-span {
            margin-left: 10%;
            margin-right: auto;
        }

        .leadership-skills-div {
            justify-content: flex-start;
        }

        .img-logo {
            width: auto;
            height: 33px;
        }

        .CircularProgressbarDiv,
        .profile-recommended-img {
            width: 200px;
        }

        .profile-img-logo {
            width: 120px;
        }

        .profile-details-status,
        .profile-img-div,
        .profile-img-logo {
            height: 170px;
            justify-content: center;
        }

        .profile-suspected {
            height: auto;
            width: 60px;
            right: 50%;
            bottom: 15px;
        }

        .profile-div {
            min-height: 170px;
        }

        .profile-recommended {
            font-size: 10.5px;
            width: 200px;
            line-height: 26.38px;
            right: 50px;
        }

        .candidate-skills-wrapper {
            width: 92%;
            margin: 4%;
            border-radius: 50px;
        }

        .radar-graph-labels-main {
            margin-top: -12px;
        }

        .soft-skills-graph-wrapper {
            width: 92%;
            margin: 4%;
        }

        .chart-wrapper {
            width: 200px;
            height: 200px;
        }

        .overall-skills-div {
            display: block;
            min-height: 350px;
            margin: 0;
        }

        .candidate-skills {
            height: 80%;
        }

        .overAll-skills {
            height: 380px;
            width: 92%;
            margin: 4%;
        }

        .space-2 {
            margin: 0;
        }

        .toplayer-ellip-img {
            width: 70px;
            height: 70px;
        }

        .code-content,
        .font-style-percent {
            font-size: 14px;
            line-height: 19px;
        }

        .leadership-skills-span,
        .phychological-skills-span,
        .phychological-skills-spanv2,
        .soft-skills-span {
            line-height: 40px;
            font-size: 16px;
        }

        .phychological-skills-span,
        .phychological-skills-spanv2 {
            text-align: left;
            margin-left: 10%;
        }

        .leadership-skills-div,
        .soft-skills-div {
            height: 40px;
            width: 60%;
            margin-bottom: 10px;
        }

        .skills-div {
            height: 40px;
            width: 92%;
            margin: 0 auto;
            clip-path: none;
            text-align: center;
        }

        .candidate-skills-heading,
        .font-style-header {
            font-size: 16px;
            line-height: 22px;
        }

        .other-skills,
        .other-skillsv2 {
            display: block;
            margin-bottom: 0;
        }

        .overAll-skills-header {
            height: 40px;
            justify-content: center;
            clip-path: none;
            margin-right: inherit;
        }

        .graph-img,
        .other-skillsv2 .m1-img {
            width: 80px;
        }

        .graph-descp-comment,
        .graph-title {
            font-size: 10px;
            line-height: 14px;
        }

        .individual-graph-wrapper {
            width: 33%;
            display: inline-block;
            padding: 3%;
            text-align: center;
        }

        .detailed-feedback-title {
            font-size: 24px;
            line-height: 33px;
        }

        .soft-skill-header {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.04em;
        }

        .font-style-soft-skill {
            font-size: 16px;
            letter-spacing: 0.04em;
            width: 100%;
            padding: 2%;
        }

        .leadership-skills-wrapperv2,
        .soft-skills-wrapper,
        .soft-skills-wrapperv2 {
            width: 100%;
            display: inline-block;
            margin: 20px 0;
        }

        .phychological-skills-wrapper,
        .phychological-skills-wrapperv2 {
            width: 100%;
            display: inline-block;
        }

        .soft-skill-wrapper,
        .soft-skill-wrapperv2 {
            min-height: auto;
            display: block;
            margin: 25px;
        }

        .screenshots-container {
            display: block;
            text-align: center;
        }

        .soft-skill-header-outer {
            display: none;
        }

        .soft-skill-header-600 {
            display: block;
            height: 40px;
            width: 100%;
            margin-bottom: 10px;
            clip-path: none;
            padding: 0;
            line-height: 40px;
            text-align: center;
            font-size: 18px;
        }

        .soft-skill-content {
            padding: 10px 20px;
            width: 100%;
        }

        .profile-details-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
        }

        .ques-ans-wrapper {
            margin: 4%;
        }

        .profile-intId {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
        }

        .feedback-status {
            font-size: 12px;
            line-height: 20px;
        }

        .profile-contact {
            font-weight: 100;
            font-size: 14px;
            line-height: 20px;
            display: flex;
        }

        .overAll-skills-span {
            margin-right: inherit;
        }

        .common-profile-text {
            color: #0d0d0d;
        }

        .screenshot-wrapper,
        .screenshot-wrapper-code {
            width: 92%;
            padding: 4%;
            margin: 0 auto;
        }

        .fill-img1 {
            height: 67%;
            left: 15%;
            position: absolute;
            top: 11%;
            width: 70%;
        }

        .profile-details-div {
            width: 92%;
            padding: 4%;
            justify-content: space-around;
            display: flex;
        }

        .rating-div2,
        .rating-system {
            display: inline-block;
        }

        .code-snippet-wrapper,
        .detailed-feedback,
        .detailed-feedback-list,
        .screening-ques-wrapper,
        .skill-rating-div {
            margin-bottom: 0;
        }

        .rating-div2 {
            margin-left: 30px;
            overflow: clip;
            width: 150px;
        }

        .other-skillsv2 .m2-img {
            width: 57px;
        }

        .other-skillsv2 .m3-img {
            width: 110px;
        }

        .other-skills .m1-img {
            left: 150px;
            top: 12%;
            width: 75px;
        }

        .other-skills .m2-img {
            left: 173px;
            top: 45%;
            width: 52px;
        }

        .other-skills .m3-img {
            position: absolute;
            width: 105px;
            left: 120px;
            top: 75%;
        }

        .CircularProgressbar {
            width: 55px;
            text-align: center;
        }

        .CircularProgressbarDiv {
            position: relative;
            text-align: center;
            top: 70px;
        }

        .CircularProgressbarText {
            color: #fff;
            position: absolute;
            right: 46%;
            text-align: center;
            top: 8px;
            font-size: 16px;
        }

        .CircularProgressbarText p {
            margin: 0;
            font-size: 10px;
        }

        .vector-tl {
            width: 30px;
        }

        .ellip-div {
            width: 100px !important;
        }

        .vector-tl-div {
            width: 150px !important;
        }

        .secondlayer-ellip {
            margin-top: 10px;
        }

        .vector-center {
            width: 100px !important;
            margin-top: -80px !important;
        }

        .rating-system span {
            padding: 0 15px;
        }
    }

    @media only screen and (max-width: 500px) {
        .individual-graph-wrapper {
            display: inline-block;
            padding: 3%;
            text-align: center;
            width: 50%;
        }

        .CircularProgressbarDiv,
        .profile-recommended-img {
            width: 150px;
        }

        .graph-descp-comment,
        .graph-title {
            font-size: 11px;
            line-height: 14px;
        }
    }
}
