.privacy-page {
    background-color: $theme-bg;
    .gap {
        margin: 10px 0;
    }
    .privacy-intro {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title {
            margin: 2% 0;
        }
        .subtitle {
            margin: 2% 0;
            width: 80%;
            text-align: justify;
        }
        .eu-link {
            width: 95%;
            color: blue;
            text-decoration: underline;
            text-decoration-color: blue;
            text-align: end;
        }
    }
    .eu-sub-container {
        margin: 0 auto;
        width: 80%;
        text-align: justify;
    }
    .privacy-info {
        width: 80%;
        margin: 0 auto;
        text-align: justify;
        li {
            margin: 10px 0;
        }
        .name,
        .email {
            text-decoration: none;
            color: $theme-blue;
        }
    }
}
